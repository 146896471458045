import React, { Fragment, useEffect, useState } from "react";
import ProductDetailSection from "../Sections/ProductDetails/ProductDetailSection";
import "../../assets/css/ProductDetails.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useLocation, useParams } from "react-router-dom";
import { fetchProductDetails, fetchReviewById } from "../../utils";
import Logo from "../../assets/images/logo.png";
const ProductDetails = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState({});

  const [loading, setLoading] = useState(true); // Added loading state
  const queryParams = new URLSearchParams(location.search);
  const size = queryParams.get("size");
  useEffect(() => {
    const fetchProduct = async () => {
      const fetchProducts = await fetchProductDetails(slug, size);
      setProductDetails(fetchProducts);
      setLoading(false); // Stop loading when data is fetched
    };
    fetchProduct();
  }, [slug]);

  if (loading) {
    return (
      <div className="loading-component">
        <img src={Logo} alt="" />
      </div>
    );
  }

  return (
    <Fragment>
      <Header />
      <ProductDetailSection productDetails={productDetails} />
      <Footer />
    </Fragment>
  );
};

export default ProductDetails;
