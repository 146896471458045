import React from "react";

const CustomArrowRight = () => (
  <svg
    width="35"
    height="78"
    viewBox="0 0 35 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66097 74L29.022 40.372L6.66097 4.906"
      stroke="#0C0C0C"
      stroke-miterlimit="10"
    />
  </svg>
);

export default CustomArrowRight;
