import React, { useState, useRef } from "react";
import "../../assets/css/SearchBarPopup.scss";
import { CiSearch } from "react-icons/ci";
import { baseURL } from "../../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText } from "@mui/material";

const SearchBarPopup = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debounceRef = useRef(null);
  const navigate = useNavigate();
  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`${baseURL}/suggestions?q=${query}`);
      const result = response.data;
      setSearchResults(result.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      if (query) {
        fetchSearchResults(query);
      } else {
        setSearchResults([]);
      }
    }, 300); // Adjust delay as needed
  };

  const handleSearchRedirect = () => {
    const formattedQuery = searchQuery.trim().replace(/\s+/g, "-");
    if (formattedQuery) {
      const values = {
        name: "Search Results",
        slug: "search",
      };
      localStorage.setItem("selectedItem", JSON.stringify(values));
      window.location.href = `/search?q=${formattedQuery}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect();
    }
  };
  const handleItemSelect = (item) => {
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(item));
    }
    if (item.slug === "rugs") {
      localStorage.removeItem("selectedItem");
    }

    window.location.href = `/${item.slug}`;
  };
  return (
    <div className="searchbar-popup-page-container">
      <div className="searchbar-popup-main-content">
        <div className="main-popup-div-for-input">
          <CiSearch />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
          />
        </div>
        {searchResults.length > 0 && (
          <div className="searchResults">
            <List>
              {searchResults.map((result, index) => (
                <ListItem key={index} onClick={() => handleItemSelect(result)}>
                  <ListItemText
                    primary={result.name
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                    className="listItemSearch"
                  />{" "}
                  {/* Customize as per result structure */}
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarPopup;
