import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaFacebook } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import "../../assets/css/CheckoutSignup.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";
import cart2 from "../../assets/images/cart2.png";
import CheckoutOrderSummary from "../Common/CheckoutOrderSummary";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../store/order";
import axios from "axios";
import { baseURL } from "../../utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../Common/Loader";
import { Snackbar, Alert } from "@mui/material";
const CheckoutSignup = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.order);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (savedOrder.length > 0) {
      dispatch(orderActions.setOrder(savedOrder));
    }
  }, [dispatch]);

  // Save order to localStorage when orderItems are updated
  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(orderItems));
  }, [orderItems]);

  const paymentData = [
    {
      name: "EMI from ₹6386/month",
      plan: "View plans",
    },
    {
      name: "Save up to ₹100",
      plan: "View offers",
    },
  ];
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    dateOfBirth: Yup.string()
      .required("Date of Birth is required")
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
        "Date of Birth must be in DD-MM-YYYY format"
      ),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must contain one lowercase, one uppercase, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const handleSubmit = async (values) => {
    // Convert dateOfBirth from DD-MM-YYYY to YYYY-MM-DD format for API submission
    const [day, month, year] = values.dateOfBirth.split("-");
    const formattedDateOfBirth = `${year}-${month}-${day}`;

    const dataToSend = {
      ...values,
      dateOfBirth: formattedDateOfBirth, // replace dateOfBirth with formatted date
      // gender: values.gender.toLowerCase(), // Convert gender to lowercase
    };

    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/auth/register`, dataToSend);

      if (response.data.success === true) {
        setSnackbarMessage("Registration successful! Please log in.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/checkout"), 2000); // Redirect after 2 seconds
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <Header />
      <div className="checkout-signup-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account - Sign Up
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account - Sign Up
          </p>
        </div>
        <div className="checkout-signup-left-box">
          <div className="signup-form-content">
            <h4>SIGN UP</h4>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                dateOfBirth: "",

                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form className="signup-form-main-content">
                  <div className="checkout-signup-form">
                    <div className="form-field-checkout">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name *"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-field-checkout">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name *"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="checkout-signup-form">
                    <div className="form-field-checkout">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email address *"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="form-field-checkout">
                      {isFocused || values.phone ? (
                        <span className="default-india-code-checkout">+91</span>
                      ) : null}
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Phone Number *"
                        style={{
                          paddingLeft:
                            isFocused || values.phone ? "51px" : "20px", // Adjust 12px to your normal padding
                        }}
                        // value={valuePhone}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        // onChange={(e) => setValuePhone(e.target.value)}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="checkout-signup-form">
                    <div className="form-field-checkout">
                      <Field
                        type="text"
                        name="dateOfBirth"
                        placeholder="Date of Birth (DD-MM-YYYY)"
                        className="date-input"
                      />
                      <ErrorMessage
                        name="dateOfBirth"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="checkout-signup-form">
                    <div className="form-field-checkout">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password *"
                      />
                      <span
                        className="password-checkout-signup-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                      <p className="password-text-checkout">
                        Must contain one lowercase, one uppercase, one number,
                        and one special character.
                      </p>
                    </div>
                    <div className="form-field-checkout">
                      <Field
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password *"
                      />
                      <span
                        className="confirmpassword-checkout-signup-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="buttons-text-checkout">
                    <p>
                      {" "}
                      By proceeding I agree to the 
                      <Link to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>{" "}
                      and <Link to="/privacy-policy">Privacy Policy</Link>
                    </p>
                    <button type="submit" disabled={loading}>
                      {loading ? <Loader /> : "SIGN UP"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <p className="sign-in-with">
              Or continue with{" "}
              <span onClick={() => navigate("/checkout")}>Log in</span> ?
            </p>

            <div className="google-facebook">
              {/* <p>OR SIGNUP USING</p> */}
              <div className="facebook-google-button-checkout">
                <button>
                  <FaFacebook /> FACEBOOK
                </button>
                <button>
                  <AiFillGoogleCircle /> GOOGLE
                </button>
              </div>
            </div>
          </div>
          <CheckoutOrderSummary order={orderItems} />
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CheckoutSignup;
