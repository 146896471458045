import React from 'react';
import "../../../assets/css/PrintAds.scss";

const PrintAds = () => {
  return (
    <div className='printads-page-container'>
      <h2 style={{textAlign:"left"}}>Coming Soon...</h2>
    </div>
  )
}

export default PrintAds
