import {
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
  Dialog,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/Header.scss";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { CiLocationOn, CiSearch } from "react-icons/ci";
import logo from "../../assets/images/logo.png";
import { BiUser } from "react-icons/bi";
import MenuList from "../Sections/Header/MenuList";
import MenuIcon from "@mui/icons-material/Menu";
import logoMob from "../../assets/images/logo-mob.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenu } from "../../store/menu-actions";
import SearchBarPopup from "../Common/SearchBarPopup";
import { baseURL } from "../../utils";
import axios from "axios";
import ShopListMob from "../Sections/Header/ShopListMob";
import { cartActions } from "../../store/cart";
import { RxCross1 } from "react-icons/rx";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Header = ({ isScrolled, isHomePage }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [shopDataNob, setShopDataMob] = useState([]);
  const [openShopList, setOpenShopList] = useState("");
  const [isTransparent, setIsTransparent] = useState(true);
  const navigate = useNavigate();
  const searchRef = useRef(null); // Reference for search area
  const debounceRef = useRef(null);
  const [searchVisible, setSearchVisible] = useState(false);
  // const [isMenuIconOpen, setIsMenuIconOpen] = useState(true);
  // const closeTheMenu = () => {
  //   setIsMenuIconOpen(false); // Close the menu
  // };
  const [isShopOpen, setIsShopOpen] = useState(false);

  const toggleShopState = () => {
    setIsShopOpen((prev) => !prev); // Toggles between true and false
  };
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );
  // Toggle Drawer
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu.menuData);

  const cartItems = useSelector((state) => state.cart.cart);
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  useEffect(() => {
    if (menu.length === 0) {
      dispatch(fetchMenu());
    }
  }, [dispatch, menu]);
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn"));
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  // const handleSearchChange = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   if (debounceRef.current) clearTimeout(debounceRef.current);

  //   debounceRef.current = setTimeout(() => {
  //     if (query) {
  //       fetchSearchResults(query);
  //     } else {
  //       setSearchResults([]);
  //     }
  //   }, 300); // Adjust delay as needed
  // };
  const handleShopClick = (item) => {
    setOpenShopList(!openShopList);
    setShopDataMob(item.submenus);
  };
  const handleRedirect = () => {
    navigate("/");
  };

  const handleAccountClick = () => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true"; // Compare to the string "true"
    console.log("Logged In:", loggedIn); // Debugging log
    if (loggedIn) {
      console.log("Redirecting to My Account");
      navigate("/my-account");
    } else {
      console.log("Redirecting to Login");
      navigate("/login");
    }
  };
  const handleCart = () => {
    window.location.href = "/cart";
  };
  const handleStore = () => {
    navigate("/store-locator");
  };
  const handleSearchPopupOpen = () => {
    setSearchPopupOpen(true);
  };

  const handleSearchPopupClose = () => {
    setSearchPopupOpen(false);
  };
  const totalCartItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`${baseURL}/suggestions?q=${query}`); // Adjust API endpoint
      const result = response.data;
      setSearchResults(result.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      if (query) {
        fetchSearchResults(query);
      } else {
        setSearchResults([]);
      }
    }, 300); // Adjust delay as needed
  };

  const handleSearchRedirect = () => {
    const formattedQuery = searchQuery.trim().replace(/\s+/g, "-");
    if (formattedQuery) {
      const values = {
        name: "Search Results",
        slug: "search",
      };
      localStorage.setItem("selectedItem", JSON.stringify(values));
      window.location.href = `/search?q=${formattedQuery}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect();
    }
  };
  const handleItemSelect = (item) => {
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(item));
    }
    if (item.slug === "rugs") {
      localStorage.removeItem("selectedItem");
    }

    window.location.href = `/${item.slug}`;
  };
  const handleMenuMobRedirect = (item) => {
    if (item.title === "Shop") {
      handleShopClick(item); // Call the new function
    } else {
      setDrawerOpen(false); // Close the drawer for other menu items
      navigate(item.url);
    }
  };
  const handleSearchClick = () => {
    setSearchVisible((prev) => !prev);
  };
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsTransparent(false); // Remove transparency on scroll
      } else {
        setIsTransparent(true); // Add transparency back at the top
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`header ${
        isHomePage && !isScrolled ? "header-transparent" : ""
      }`}
    >
      <Container className="container" maxWidth="lg" disableGutters>
        <div className="discountContainer">
          {/* <IconButton className="discountArrow">
            <SlArrowLeft />
          </IconButton> */}
          <div className="discountText">
            10% Off ON FIRST PURCHASE! USE CODE "Firstorder"
          </div>
          {/* <IconButton className="discountArrow">
            <SlArrowRight />
          </IconButton> */}
        </div>
        <div className="logoCartSearchContainer">
          <div className="storeLocator">
            <div className="locationIcon">
              <CiLocationOn />
            </div>
            <div className="locatorText" onClick={handleStore}>
              Store Locator
            </div>
          </div>
          <div
            className="logoContainer"
            style={{ cursor: "pointer" }}
            onClick={handleRedirect}
          >
            <img
              src={logo}
              alt="logo"
              style={{ cursor: "pointer" }}
              onClick={handleRedirect}
            />
          </div>
          <div className="searchCartContainer">
            <div
              className={`searchBox ${searchVisible ? "searchVisible" : ""}`}
              ref={searchRef}
            >
              <div className="searchIcon" onClick={handleSearchClick}>
                <CiSearch />
              </div>
              {searchVisible && (
                <div className="searchInput" onClick={handleSearchRedirect}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                  />
                </div>
              )}
            </div>
            {searchResults.length > 0 && (
              <div className="searchResults" ref={searchRef}>
                <List>
                  {searchResults.map((result, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleItemSelect(result)}
                    >
                      <ListItemText
                        primary={result.name
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                        className="listItemSearch"
                      />{" "}
                      {/* Customize as per result structure */}
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            <div className="account" onClick={handleAccountClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="currentColor" stroke-linecap="round">
                  <path d="M19.727 20.447c-.455-1.276-1.46-2.403-2.857-3.207S13.761 16 12 16s-3.473.436-4.87 1.24s-2.402 1.931-2.857 3.207" />
                  <circle cx="12" cy="8" r="4" />
                </g>
              </svg>
            </div>
            <div className="cart" onClick={handleCart}>
              <Badge
                badgeContent={totalCartItems} // Set the badge content to "0"
                showZero // Ensure the "0" is shown
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // sx={{
                //   "& .MuiBadge-badge": {
                //     backgroundColor: "#000000",
                //     color: "#FFFFFF",
                //   },
                // }}
                className="customBadge"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 32 32"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M6 6h24l-3 13H9m18 4H10L5 2H2" />
                    <circle cx="25" cy="27" r="2" />
                    <circle cx="12" cy="27" r="2" />
                  </g>
                </svg>
              </Badge>
            </div>
          </div>
        </div>
        <div
          className={`header-mob ${
            isHomePage && isTransparent ? "header-mob-transparent" : ""
          }`}
        >
          <div className="logo-mob">
            <img src={logoMob} alt="logo" onClick={handleRedirect} />
          </div>
          <div className="logoCartMenuContainer">
            <div className="search-mob" onClick={handleSearchPopupOpen}>
              <CiSearch />
            </div>
            <div className="cart-mob" onClick={handleCart}>
              <Badge
                badgeContent={totalCartItems} // Set the badge content to "0"
                showZero // Ensure the "0" is shown
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#000000",
                    color: "#FFFFFF",
                    fontSize: "0.5rem",
                    padding: "0 2px",
                    minWidth: "15px",
                    height: "15px",
                  },
                }}
                className="badge-color"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 32 32"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M6 6h24l-3 13H9m18 4H10L5 2H2" />
                    <circle cx="25" cy="27" r="2" />
                    <circle cx="12" cy="27" r="2" />
                  </g>
                </svg>
              </Badge>
            </div>
            <div className="navDrawer">
              <IconButton
                onClick={toggleDrawer(true)}
                className="menu-header-mob-icon"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              width: "1000%", // Control width of the drawer
              maxWidth: "100%", // For larger screens, limit drawer width
              maxHeight: "100%",
              // minHeight: "auto",
              height: "100%",
              paddingTop: "10px",
              paddingBottom: "20px",
              overflowY: "scroll",
            },
          }}
          style={{ fontFamily: "Lato, sans-serif", fontSize: "12px" }}
          className="drawerMob"
        >
          <div
            className="drawerContent"
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <div className="add-bottom-border">
              <div className="menu-logo-and-cross-icon">
                <div className="menu-logo-ddecor">
                  <img src={logoMob} alt="logo" />
                </div>
                <div className="menu-cross-icon" onClick={toggleDrawer(false)}>
                  <RxCross1 />
                </div>
              </div>
            </div>
            <List className="list-drawer">
              {menu.map((item, index) => (
                <>
                  <div
                    className="list-text-with-arrow-icon"
                    onClick={index === 0 ? toggleShopState : null}
                  >
                    <ListItem
                      button
                      key={item.slug}
                      onClick={() => handleMenuMobRedirect(item)}
                      className="list-drawer-head"
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                    {index === 0 ? (
                      openShopList ? (
                        <MdKeyboardArrowUp />
                      ) : (
                        <MdKeyboardArrowDown />
                      )
                    ) : null}
                  </div>
                  {/* Conditionally render ShopListMob directly below the "Shop" item */}
                  {item.title === "Shop" && openShopList && (
                    <ShopListMob subMenu={shopDataNob} />
                  )}
                </>
              ))}
            </List>
          </div>
        </Drawer>
        <MenuList menuList={menu} />
        <Dialog open={searchPopupOpen} onClose={handleSearchPopupClose}>
          <SearchBarPopup />
        </Dialog>
      </Container>
    </header>
  );
};

export default Header;
