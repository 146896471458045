import { baseURL } from "../utils";
import { categoryActions } from "./category";
import axios from "axios";

export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseURL}/categories`);
      const result = response.data;

      dispatch(
        categoryActions.getCategory({
          categories: result.data || [],
        })
      );
    } catch (error) {}
  };
};
