import React, { Fragment, useState } from "react";
import "../../../assets/css/Abstract.scss";
import FilterList from "./FilterList";
import Products from "./Products";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
const MainProduct = ({
  sizes,
  colors,
  rooms,
  patterns,
  shapes,
  collections,
  categories,
  filterParams,
  updateFilters,
  filteredProducts,
  count,
  filterValues,
  range,
  handleLoadMore,
}) => {
  const selectedItem = JSON.parse(localStorage.getItem("selectedItem"));
  const [showViewCart, setShowViewCart] = useState(false);
  const navigate = useNavigate();
  const handleViewCart = () => {
    setShowViewCart(true); // Show "View Cart" when triggered
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Hide "View Cart" after a few seconds
    setTimeout(() => {
      setShowViewCart(false);
    }, 6000);
  };
  const handleClickViewCart = () => {
    window.location.href = "/cart";
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          {selectedItem
            ? `${selectedItem.name} | Rugs | D'Decor`
            : "All Rugs | D'Decor"}
        </title>
        <meta
          property="og:title"
          content={selectedItem ? selectedItem.metaTitle : "defaultTitle"}
        />
        <meta
          name="description"
          content={
            selectedItem ? selectedItem.metaDescription : "defaultDescription"
          }
        />
        <meta
          property="og:description"
          content={
            selectedItem ? selectedItem.metaDescription : "defaultDescription"
          }
        />
      </Helmet>
      {showViewCart && (
        <div className="viewCartBoxContainer">
          <div className="addToCartBox">
            <p className="boxText">Product has been added to cart.</p>
            <button className="viewCartBox" onClick={handleClickViewCart}>
              {/* View Cart */}
              VIEW CART
            </button>
          </div>
        </div>
      )}
      <div className="abstract-page-container">
        <div className="abstract-main-content">
          <FilterList
            sizes={sizes}
            colors={colors}
            rooms={rooms}
            shapes={shapes}
            patterns={patterns}
            collections={collections}
            categories={categories}
            filterParams={filterParams}
            updateFilters={updateFilters}
            range={range}
          />
          <Products
            sizes={sizes}
            colors={colors}
            rooms={rooms}
            shapes={shapes}
            patterns={patterns}
            collections={collections}
            categories={categories}
            filterParams={filterParams}
            updateFilters={updateFilters}
            products={filteredProducts}
            count={count}
            filterValues={filterValues}
            showViewCart={showViewCart}
            handleViewCart={handleViewCart}
            handleLoadMore={handleLoadMore}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MainProduct;
