import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Image1 from "../../assets/images/Image [attachment-full].png";
// import Blog1 from "../../assets/images/Rectangle 6741.png";
// import img1 from "../../assets/images/img-6.jpg";
// import img2 from "../../assets/images/img-7.jpg";
// import img3 from "../../assets/images/img-8.png";
// import img4 from "../../assets/images/img-9.jpg";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import blogimage1 from "../../assets/images/blogimage1.jpg";
import blogimage2 from "../../assets/images/blogimage2.jpg";
import blogimage3 from "../../assets/images/blogimage3.jpg";
import blogimage4 from "../../assets/images/blogimage4.jpg";
import blogimage5 from "../../assets/images/blogimage5.jpg";
import blogimage6 from "../../assets/images/blogimage6.jpg";
import newblogimage3 from "../../assets/images/newblogimage3.jpg";
import newblogimage1 from "../../assets/images/newblogimage1.jpg";
import "../../assets/css/BlogInner.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const BlogInner = () => {
  const [clicked, setClicked] = useState(false); // Track whether the post box is clicked
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  const recentPosts = [
    {
      imgSrc: blogimage2,
      title: "How to Choose the Perfect Rug for Every Room",
      date: "Sept, 2024",
      slug: "/blog-inner-two",
    },
    {
      imgSrc: newblogimage1,
      title:
        "The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor",
      date: "Nov, 2024",
      slug: "/blog-inner-three",
    },
  ];
  // Handle navigation when a post is clicked
  const handlePostClick = (slug) => {
    navigate(slug);
  };

  return (
    <Fragment>
      <Header />
      <div class="intro-bloginner-container">
        <div class="heading-bloginner">
          <h2>
            Home - <span>Blogs</span> -
            <span>Unleash Your Style: Statement Living with Abstract Rugs</span>
          </h2>
        </div>
      </div>

      <div class="blog-content-container">
        <div class="content-container-1">
          <div class="blog-image-box">
            <img src={blogimage1} alt="" />
            <p>July, 2024</p>
          </div>
          <div class="blog-text">
            {/* <h6>Lorem Ipsum - Lorem</h6> */}
            <h3>Unleash Your Style: Statement Living with Abstract Rugs</h3>
            <p>
              Your home is your canvas, and every piece of furniture or décor
              contributes to the masterpiece that is your living space. <br />{" "}
              <br />
              One of the most transformative yet often overlooked elements in
              home design? Rugs. But not just any rugs—abstract rug designs.
              These dynamic, artistic pieces are more than just something soft
              to walk on; they’re statement-makers that can breathe life,
              personality, and a unique vibe into your home.
            </p>
            <div class="blog-content-img">
              <img src={blogimage3} alt="" />
            </div>
            <h5>Creating a Statement in the Living Room</h5>
            <p>
              The living room is often where abstract rugs truly shine. The
              expansive space provides the perfect backdrop for larger, bolder
              designs to take center stage. Imagine a rug with vibrant color
              contrasts—where deep blues meet sunny yellows and unexpected
              bursts of red. When placed beneath minimalist furniture, this
              artistic piece transforms the room into a gallery-like space,
              allowing the rug to function as an art installation as much as a
              functional item.
            </p>
            <div class="blog-content-img">
              <img src={blogimage4} alt="" />
            </div>
            <h5>Calming Influence in the Bedroom</h5>
            <p>
              In the bedroom, abstract rugs work their magic in a different way.
              Here, the goal is to foster relaxation, and choosing designs with
              calming, organic shapes in soothing tones can achieve just that.
              Consider soft greys, dusty blues, or warm, earthy neutrals. When
              paired with natural fabrics and minimalistic furniture, an
              abstract rug can create a serene retreat—a cocoon of calm that
              encourages rest and relaxation.
            </p>
            <div class="blog-content-img">
              <img src={blogimage5} alt="" />
            </div>
            <h5>Energize Your Workspace</h5>
            <p>
              Home offices or creative spaces benefit immensely from the
              introduction of abstract rug designs. Often, these rooms can feel
              sterile or purely functional, but an abstract rug can change the
              mood in an instant. Whether you opt for something bold with vivid
              color contrasts or a subtler design that plays with form and line,
              the rug becomes an anchor for creativity. It's a reminder that
              your workspace is just as deserving of character and charm as the
              rest of your home.
            </p>
            <div class="blog-content-img">
              <img src={blogimage6} alt="" />
            </div>
            <h5>Adding Sophistication to Dining Spaces</h5>
            <p>
              Dining rooms, typically more structured and formal, benefit from
              the unexpected contrast of an abstract rug. It adds a touch of
              playfulness and softens the room's atmosphere, creating a space
              that feels welcoming yet sophisticated. A more neutral palette in
              the rug allows you to maintain an elegant setting, while still
              introducing a splash of artistic flair that elevates the dining
              experience.
            </p>
            <h5>Versatility Across Spaces</h5>
            <p>
              One of the most appealing aspects of abstract rugs is their
              versatility. Whether you're decorating a modern loft, a cozy
              suburban home, or a rustic cabin, there's an abstract design that
              will complement your space. These rugs offer flexibility in both
              color and form, making them perfect for rooms with various
              styles—be it minimalist, eclectic, or transitional. <br />
              <br />
              Abstract rug designs go beyond mere décor; they offer a form of
              self-expression, transforming any space into a personal statement.
              By choosing the right abstract rug, you’re not only adding warmth
              and comfort to a room, but you’re also introducing a sense of
              artistry, creativity, and individuality.
            </p>
          </div>
        </div>

        <div class="content-container-2">
          <h2>Recent Post</h2>
          {recentPosts.map((post, index) => (
            <div
              key={index}
              className="recent-post-box"
              onClick={() => handlePostClick(post.slug)} // Pass the slug dynamically
            >
              <div className="recent-img">
                <img src={post.imgSrc} alt="" />
              </div>
              <div className="recent-content">
                <h4>{post.title}</h4>
                <p>{post.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BlogInner;
