import React, { Fragment, useEffect, useState } from "react";
import Cookies from "js-cookie"; // Import js-cookie
import Header from "../Layout/Header";
import Banner from "../Sections/Home/Banner";
import Category from "../Sections/Home/Category";
import NewArrivals from "../Sections/Home/NewArrivals";
import Collection from "../Sections/Home/Collection";
import BestSeller from "../Sections/Home/BestSeller";
import CustomerReview from "../Sections/Home/CustomerReview";
import ProgramFeatured from "../Sections/Home/ProgramFeatured";
import HomeRugs from "../Sections/Home/HomeRugs";
import HomeSocial from "../Sections/Home/HomeSocial";
import Footer from "../Layout/Footer";
import Logo from "../../assets/images/logo.png";
import SubscribePopup from "../Sections/Home/SubscribePopup";
import {
  fetchBanners,
  fetchBestSellers,
  fetchFeaturedCollection,
  fetchFeaturedIn,
  fetchHomeCategories,
  fetchNewArrivals,
} from "../../utils";
import "../../assets/css/Home.scss";
const Home = () => {
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newArrivals, setNewArrival] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [collection, setCollection] = useState([]);
  const [featuredInData, setFeaturedInData] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const isHomePage = window.location.pathname === "/";

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch banners and categories first
        const [bannerData, categoryData, newArrivalData] = await Promise.all([
          fetchBanners(),
          fetchHomeCategories(),
          fetchNewArrivals(),
        ]);
        setBanners(bannerData);
        setCategories(categoryData);
        setNewArrival(newArrivalData);
        // Set loading to false only after banners and categories are available
        setLoading(false);

        // Fetch the rest of the data in parallel
        const [bestSellerData, collectionData, featuredInData] =
          await Promise.all([
            fetchBestSellers(),
            fetchFeaturedCollection(),
            fetchFeaturedIn(),
          ]);

        setBestSellers(bestSellerData);
        setCollection(collectionData);
        setFeaturedInData(featuredInData);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false); // Ensure loading is false even if an error occurs
      }
    };

    fetchInitialData();
  }, []);
  // useEffect(() => {
  //   // Set loading to false only when banners and categories are available
  //   if (banners.length > 0 && categories.length > 0) {
  //     setLoading(false);
  //   }
  // }, [banners, categories]);
  useEffect(() => {
    if (!isHomePage) return; // Don't run scroll effect on other pages

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true); // When scrolled more than 50px, set isScrolled to true
      } else {
        setIsScrolled(false); // Reset when scrolled back to the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHomePage]);

  useEffect(() => {
    if (!isHomePage) return;

    const hasPopupBeenShown = Cookies.get("popupShown");

    if (!hasPopupBeenShown) {
      // Show the popup after 3 seconds
      const timer = setTimeout(() => {
        setShowPopup(true);
        Cookies.set("popupShown", "true", { expires: 7 }); // Set cookie to expire in 7 days
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [isHomePage]);

  const handleClosePopup = () => setShowPopup(false); // Handle popup close
  return (
    <Fragment>
      {loading ? (
        <div className="loading-component">
          <img src={Logo} alt="" />
        </div>
      ) : (
        <>
          <Header isScrolled={isScrolled} isHomePage={isHomePage} />
          <Banner banners={banners} />
          <SubscribePopup isVisible={showPopup} onClose={handleClosePopup} />
          <Category categories={categories} />
          {newArrivals.length !== 0 && (
            <NewArrivals newArrivals={newArrivals} />
          )}
          <Collection collection={collection} />
          {bestSellers.length !== 0 && <BestSeller bestSellers={bestSellers} />}
          <CustomerReview />
          <ProgramFeatured featuredIn={featuredInData} />
          <HomeRugs />
          <HomeSocial />
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default Home;
