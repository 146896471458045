import React from 'react';
import "../../../assets/css/Events.scss";

const Events = () => {
  return (
    <div className='event-page-container'>
       <h2 style={{textAlign:"left"}}>Coming Soon...</h2>
    </div>
  )
}

export default Events
