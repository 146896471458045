import React, { Fragment, useState } from "react";
import "../../assets/css/FAQ.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const FAQ = () => {
  const faqs = [
    {
      category: "Shipping & Delivery",
      questions: [
        {
          question: "Where is my order?",
          answer: (
            <>
              To know the status of your order, you can contact us through below
              channels.
              <br />
              Mobile: +91 7718801634 (09:30 AM - 06:30 PM IST)
              <br />
              Email: shop@ddecorrugs.com
              <br />
              Website Chat: 09:00 AM till 06:00 PM IST.
            </>
          ),
        },
        {
          question: "What are the shipping charges?",
          answer:
            "We offer free shipping on every item purchased through our website.",
        },
        {
          question: "When will my order arrive?",
          answer:
            "We aim to dispatch all orders within 24 business hours. The estimated delivery time may vary product to product and can be delivered the next day or maximum in 3 business days from the time of placing the order. The estimated delivery can be checked on the product detail page.",
        },
        {
          question:
            "How do I check the estimated delivery date for any product?",
          answer:
            "Enter your delivery pin code on the product detail page to know the estimated delivery days for it.",
        },
        {
          question: "How will I know whether my order is confirmed?",
          answer: (
            <>
              Once the order is successfully placed, you will receive the
              following notifications via email: -
              <br />
              1. Email order confirmation
              <br />
              2. Text message order confirmation.
              <br />
              3. Email with tracking details and Invoice once the product is
              shipped.
            </>
          ),
        },
        {
          question: "How can I cancel my order?",
          answer: (
            <>
              You can cancel your order by calling us on or email us at -
              shop@ddecorrugs.com. Order +91 7718801634 cancellation will only
              be accepted before the shipment has been dispatched.
              <br />
              Once the order is cancelled, the refund shall be initiated, and it
              should reflect in your account within 48 business hours through
              the original mode of payment.
            </>
          ),
        },
      ],
    },
    {
      category: "Payment related",
      questions: [
        {
          question: "How can I pay?",
          answer: (
            <>
              You can pay using your debit card, credit card, cash wallets or
              internet banking through our secure payment gateway. You can also
              pay through Bank Transfer. Cash on delivery is available in India
              for cart value below 50,000 INR. To know more, kindly call us at
              +91 7718801634 or drop us a mail at shop@ddecorrugs.com.
            </>
          ),
        },
        {
          question: "Is shipping free?",
          answer: "Free Shipping across India",
        },
        {
          question:
            "Are the prices, which appear on the website, inclusive of all taxes and duties?",
          answer:
            "For India: No hidden cost. Listed price is final inclusive of all taxes and shipping.",
        },
        {
          question: "Do you offer EMI option for payment?",
          answer:
            "Yes. We provide an EMI option for payment at the payment page post checkout.",
        },
      ],
    },
    {
      category: "Order Cancellation",
      questions: [
        {
          question: "How can I make a change in my order or cancel my order?",
          answer: (
            <>
              Any change or cancellation is only possible if the information is
              received before the order gets dispatched. Please get in touch
              with us for the same at +91 7718801634 or email us at
              shop@ddecorrugs.com.
            </>
          ),
        },
        {
          question: "When can I cancel my order?",
          answer:
            "You can cancel your order online before the shipment of the product is complete, after which the entire amount will be refunded. However, an order cannot be cancelled once the shipment has been made.",
        },
      ],
    },
    {
      category: "Return Policy",
      questions: [
        {
          question: "What is your return policy?",
          answer: (
            <>
              India: All items purchased online are eligible for 15 days
              hassle-free return. In case of non-manufacturing defects, the
              shipping cost for the return has to be borne by the customer. In
              such cases, we can arrange for the logistics.
            </>
          ),
        },
        {
          question: "How do I know if an item is eligible for a return?",
          answer:
            "An item is eligible for return within 7 days of the receipt date of your order. It must be returned in the same condition in which it was delivered to you.",
        },
        {
          question: "After how many days can I return an item?",
          answer: "You can return the item within 7 days of the receipt.",
        },
        {
          question: "How long does it take to process the refund?",
          answer:
            "If the item is damage-free, a refund will be initiated by us within 48 hours after passing through quality checks, it may take 7-8 business days to reflect the amount in your account.",
        },
        {
          question: "Where do you send the refund?",
          answer:
            "Refund is processed through the same payment method which was used to place the order.",
        },
        {
          question: "I did not receive an email after initiating a return.",
          answer:
            "Call us or email us to re-request a copy of the email. We will resend it on your registered email address.",
        },
        {
          question:
            "What if my rug is damaged, defective in any way, or just not the item ordered?",
          answer:
            "Each of our rug goes through strict quality checks before it is dispatched. However, in case the rug is damaged in transit, we request you to share with us the pictures of the damaged part, as soon as you receive the rug, at shop@ddecorrugs.com, along with your order ID. After receiving your email, we will ensure resolution within 1 working day.",
        },
      ],
    },
    {
      category: "Our Stores",
      questions: [
        {
          question: "Is there a showroom I can visit?",
          answer:
            "Yes. We have multiple stores. Please visit the Contact Us page to find our store details.",
        },
      ],
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <Fragment>
      <Header />
      <div class="intro-faq-container">
        <div class="heading-faq">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>FAQS</span>
          </h2>
        </div>
      </div>

      <div class="faq-container">
        <h3>FAQs</h3>
        {faqs.map((faq, categoryIndex) => (
          <div className="accordion" key={categoryIndex}>
            <h5>{faq.category}</h5>
            {faq.questions.map((item, questionIndex) => (
              <div className="accordion__item" key={questionIndex}>
                <div
                  className={`accordion__header ${
                    activeIndex === `${categoryIndex}-${questionIndex}`
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    toggleAccordion(`${categoryIndex}-${questionIndex}`)
                  }
                >
                  <span>&#9679;</span> {item.question}
                </div>
                <div
                  className={`accordion__content ${
                    activeIndex === `${categoryIndex}-${questionIndex}`
                      ? "active"
                      : ""
                  }`}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Footer />
    </Fragment>
  );
};

export default FAQ;
