import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // For validation
import "../../assets/css/TradeProgram.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../utils";
const TradeProgram = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);

  // Validation schema
  const validationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });

  // Form submission handler
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post(`${baseURL}/join-trade`, values);
      if (response.data.success === true) {
        setSnackbarMessage("Thank you for joining our Trade Program!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        resetForm();
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something Went Wrong! Please try again later...");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  return (
    <Fragment>
      <Header />
      <div className="intro-trade-container">
        <div className="heading-trade">
          <h2>
            Home - <span>Trade Program</span>
          </h2>
        </div>
      </div>

      <div className="trade-program-container">
        <div className="image-content">
          <div className="content">
            <p>Dear B2B channel partner,</p>
            <p>
              If you are looking forward to cater to architects & consumers with
              exquisite & modern RUGS enquiries – well D'Decor Rugs can be your
              one-stop solution for all types of styles. From Decent
              Contemporaries & Authentic Classicals to Multicolour Abstracts
              & Attractive plain- Floral- Geometrics; we ensure each & every
              ongoing variety under our Rugs portfolio.
            </p>
            <p>
              Do reach out to us to witness the latest design collections by one
              of the most preferred consumer priority brands. We would be
              immensely pleased to be associated with you and to present our
              dynamic range of world-class Rugs offerings!
            </p>
          </div>
        </div>
      </div>

      <div className="trade-form-container">
        <div className="form-container">
          <h2>Join our trade program</h2>
          <Formik
            initialValues={{
              companyName: "",
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              comments: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className="trade-program-form">
                <div className="form-group">
                  <Field
                    type="text"
                    name="companyName"
                    placeholder="Company Name *"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First Name *"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last Name *"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <Field type="email" name="email" placeholder="Email *" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <Field type="tel" name="phone" placeholder="Phone no *" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Field
                    as="textarea"
                    name="comments"
                    placeholder="Comments (If any)"
                  />
                </div>
                <button type="submit" className="trade-main-button">
                  SUBMIT
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default TradeProgram;
