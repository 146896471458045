import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Transaction = () => {
  const location = useLocation();
  useEffect(() => {
    // Parse query parameters if needed
    console.log("Payment gateway response:", location.search);
  }, [location]);
  return (
    <div>
      <h2>Payment Return</h2>
      <p>Processing your payment result...</p>
    </div>
  );
};

export default Transaction;
