import React from "react";
import "../../../assets/css/ProfileSignout.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const ProfileSignout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="profile-signout-page-container">
      <div className="proffile-signout-main-content">
        <div className="profile-signout-popup-div">
          <p>Are you sure you want to sign out?</p>
          <div className="profile-signout-div-buttons">
            <button className="signout-popup-cancel-button">CANCEL</button>
            <button
              className="signout-popup-signout-button"
              onClick={handleSignOut}
            >
              SIGN OUT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSignout;
