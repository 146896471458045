import { baseURL } from "../utils";
import axios from "axios";
import { menuActions } from "./menu";

export const fetchMenu = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseURL}/menus`);
      const result = response.data;

      dispatch(
        menuActions.fetchMenuDara({
          menus: result.data || [],
        })
      );
    } catch (error) {}
  };
};
