import React from "react";
import "../../../assets/css/Profile.scss";
import moment from "moment";
const ProfileInfo = ({ onEdit, userData }) => {
  const formatDate = (dateString) => moment(dateString).format("DD/MM/YYYY");
  // const userData = [
  //   { user: "Name", userdetails: "Rahul Sharma" },
  //   // { user: "Password", userdetails: ". . . . . ." },
  //   { user: "Email Id", userdetails: "RahulSharma@gmail.com" },
  //   { user: "Mobile Number", userdetails: "9999999999" },
  //   { user: "Date of Birth", userdetails: "20-03-1999" },
  //   {
  //     user: "Address",
  //     userdetails: (
  //       <>
  //         Flat No. 12, ABC Apartments, 2nd Cross, MG Road, Koramangala,
  //         Bangalore - 560034 Karnataka, India
  //       </>
  //     ),
  //   },
  // ];

  return (
    <div className="profile-all-details-box">
      <div className="user-all-about-details">
        <label>Name</label>
        <div className="address-details">
          {userData.firstName} {userData.lastName}
        </div>
        <label>Email Id</label>
        <div className="address-details">{userData.email}</div>
        <label>Mobile Number</label>
        <div className="address-details">{userData.phone}</div>
        <label>Date of Birth</label>
        <div className="address-details">
          {formatDate(userData.dateOfBirth)}
        </div>
        <label>Address</label>
        <div className="address-details">
          {userData.address} {userData.city} {userData.zip} {userData.state}{" "}
          {userData.country}
        </div>
        {/* {typeof item.userdetails === "string" ? (
            <input type="text" placeholder={item.userdetails} readOnly />
          ) : ( */}
        {/* <div className="address-details">{item.userdetails}</div> */}
        {/* )} */}
      </div>

      <button onClick={onEdit}>EDIT</button>
    </div>
  );
};

export default ProfileInfo;
