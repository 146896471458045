import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchProducts, fetchSearchedProducts } from "../../utils";
import MainProduct from "../Sections/Products/MainProduct";
import { fetchShopData } from "../../store/shop-actions";
import { fetchCollections } from "../../store/collection-actions";
import { fetchCategories } from "../../store/category-actions";

const SearchedProducts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const shopData = useSelector((state) => state.shops.shopData);
  const collections = useSelector((state) => state.collections.collections);
  const categories = useSelector((state) => state.categories.categories);

  const sizes = shopData.sizes || [];
  const colors = shopData.colors || [];
  const rooms = shopData.rooms || [];
  const shapes = shopData.shapes || [];
  const patterns = shopData.patterns || [];

  // Extract query parameters
  const urlParams = new URLSearchParams(location.search);
  const filterParams = Object.fromEntries(urlParams.entries());
  const qParam = urlParams.get("q");
  const displayValues = location.state;
  const firstPathValue = location.pathname.split("/")[1]; // Extract the first path value

  useEffect(() => {
    dispatch(fetchShopData());
    dispatch(fetchCollections());
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    // Fetch products based on filterParams and firstPathValue
    fetchSearchedProducts(qParam, filterParams) // Merge filterParams and firstPathValue
      .then((result) => {
        setFilteredProducts(result.products); // Update products
        setProductCount(result.count); // Update count
      })
      .catch((error) => console.error(error));
  }, [location.search, firstPathValue]); // Add firstPathValue to dependencies

  const updateFilters = (newFilters) => {
    const searchParams = new URLSearchParams(newFilters).toString();
    navigate(`?${searchParams}`);
  };

  return (
    <>
      <Header />
      <MainProduct
        sizes={sizes}
        colors={colors}
        rooms={rooms}
        shapes={shapes}
        patterns={patterns}
        collections={collections}
        categories={categories}
        filterParams={filterParams}
        updateFilters={updateFilters}
        filteredProducts={filteredProducts}
        count={productCount}
        filterValues={displayValues}
      />
      <Footer />
    </>
  );
};

export default SearchedProducts;
