import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart"; // Update the path as necessary
import "../../assets/css/Cards.scss";
import { orderActions } from "../../store/order";
import VerticalCarousel from "../Sections/ProductDetails/VerticalCarousel";
import HorizontelCarousel from "../Sections/ProductDetails/HorizontelCarousel";
import { formatPrice } from "../../utils";
import Loader from "./Loader";
const ProductCard = ({ products, handleViewCart }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);
  const orders = useSelector((state) => state.order.order);
  const [isLoading, setIsLoading] = useState(false);
  const [showViewCart, setShowViewCart] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false); // Manage showing carousels
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check mobile view
  const [pid, setPid] = useState("");
  const [hoveredProduct, setHoveredProduct] = useState(null);
  // References for vertical and horizontal carousels
  const verticalCarouselRef = useRef(null);
  const horizontalCarouselRef = useRef(null);
  const [selectedSize, setSelectedSize] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize); // Listen for window resize events
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const handleProductRedirect = (slug, firstSize) => {
    const searchParams = new URLSearchParams(window.location.search);
    const sizeParam = searchParams.get("size");

    // Use size from URL if it exists, otherwise fallback to firstSize
    const sizeToUse = sizeParam || firstSize;
    window.location.href = `/product/${slug}?size=${sizeToUse}`;
    // navigate(`/product/${slug}?size=${sizeToUse}`);
  };

  const handleAddToCart = (e, item, quantity, firstSize) => {
    e.stopPropagation();

    const selectedItem = JSON.parse(localStorage.getItem("selectedItem"));
    let selectedSize;

    if (selectedItem && selectedItem.title === "Size") {
      // Find the exact match for the size within item's sizes array
      selectedSize = item.sizes.find((size) => size.slug === selectedItem.slug);
    } else {
      // Fallback to firstSize if no match is found or if title is not "Size"
      selectedSize = firstSize;
    }
    setIsLoading(true);

    dispatch(cartActions.addToCart({ ...item, quantity, selectedSize }));
    setTimeout(() => {
      setIsLoading(false); // Hide loader
      handleViewCart(); // Show "View Cart" button
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 2000); // Loader duration (2 seconds)

    // Hide "View Cart" button after 4 seconds
    setTimeout(() => {
      setShowViewCart(false);
    }, 6000); // Additional time for the "View Cart" button to be visible
  };

  const handleViewSimilar = (e, id) => {
    e.stopPropagation();
    setShowCarousel(true); // Show the carousel when "View Similar" button is clicked
    setPid(id);
    // Scroll to the relevant carousel depending on the screen size
    if (isMobile && horizontalCarouselRef.current) {
      horizontalCarouselRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!isMobile && verticalCarouselRef.current) {
      verticalCarouselRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleMouseEnter = (productId) => {
    setHoveredProduct(productId);
  };

  const handleMouseLeave = () => {
    setHoveredProduct(null);
  };
  // const handleViewCart = () => {
  //   navigate("/cart");
  // };
  return (
    <Fragment>
      {/* {showViewCart && (
        <div className="viewCartBoxContainer">
          <div className="addToCartBox">
            <p className="boxText">Product has been added to cart.</p>
            <button className="viewCartBox" onClick={handleViewCart}>
              View Cart
            </button>
          </div>
        </div>
      )} */}

      <div className="all-cards-container">
        {products.map((item) => (
          <a
            href={`/product/${item.slug}?size=${item.sizes[0].slug}`}
            target="_self"
            rel="noopener noreferrer"
            className="product-card-link" // Optional: Add class for styles, if needed
            key={item._id}
          >
            <div
              className="main-cards"
              key={item._id}
              // onClick={() =>
              //   handleProductRedirect(item.slug, item.sizes[0].slug)
              // }
            >
              <div
                className="card-image-container"
                onMouseEnter={() => handleMouseEnter(item._id)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={
                    hoveredProduct === item._id && item.images.length > 1
                      ? item.images[1].url
                      : item.images[0].url
                  }
                  alt={item.name}
                />
                {item.similarProductIds.length !== 0 && (
                  <button
                    className="view-similar"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent navigating to href
                      e.stopPropagation(); // Stop click propagation
                      handleViewSimilar(e, item._id);
                    }}
                  >
                    VIEW SIMILAR
                  </button>
                )}
                {item.productStatus !== "" && (
                  <div className="product-card-tag">{item.productStatus}</div>
                )}

                <button
                  className="add-to-cart-btn"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent navigating to href
                    e.stopPropagation(); // Stop click propagation
                    handleAddToCart(e, item, 1, item.sizes[0]);
                  }}
                  disabled={item.productStatus === "sold out"}
                >
                  {isLoading ? <Loader /> : "ADD TO CART"}
                </button>
              </div>
              <div className="title-price">
                <h6>
                  {" "}
                  {item.name
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </h6>
                <div className="size-card">{item.sizes[0].name}</div>
                <p>
                  {item.priceRange.price ? (
                    item.priceRange.priceSale ? (
                      <Fragment>
                        <span
                          style={{
                            textDecoration: "line-through",
                            marginRight: "8px",
                            color: "#b0b0b0",
                            fontWeight: "400",
                          }}
                          className="old-price-card"
                        >
                          {formatPrice(item.priceRange.price)}
                        </span>
                        <span className="new-price-card">
                          {formatPrice(item.priceRange.priceSale)}
                        </span>
                      </Fragment>
                    ) : (
                      formatPrice(item.priceRange.price)
                    )
                  ) : (
                    `${formatPrice(item.priceRange.lowest)} - ${formatPrice(
                      item.priceRange.highest
                    )}`
                  )}
                </p>
              </div>
              {/* <p>{item.sku}</p> */}
              {/* <div className="card-buttons">
              {isInCart(item) ? (
                <button
                  className="add-cart"
                  onClick={(e) => handleRemoveFromCart(e, item)}
                >
                  REMOVE FROM CART
                </button>
              ) : (
                <button
                  className="add-cart"
                  onClick={(e) => handleAddToCart(e, item, 1)}
                >
                  ADD TO CART
                </button>
              )}

              <button
                className="buy-now"
                onClick={(e) => handleBuyNow(e, item, 1)}
              >
                BUY NOW
              </button>
            </div> */}
            </div>
          </a>
        ))}
      </div>
      {showCarousel && (
        <div>
          {/* Show Vertical Carousel on Desktop/Tablet */}
          {!isMobile && (
            <div className="sidebar" ref={verticalCarouselRef}>
              <VerticalCarousel
                pid={pid}
                onClose={() => setShowCarousel(false)}
              />
            </div>
          )}

          {/* Show Horizontal Carousel on Mobile */}
          {isMobile && (
            <div
              className="horizontal-carousel-section"
              ref={horizontalCarouselRef}
            >
              <HorizontelCarousel
                pid={pid}
                onClose={() => setShowCarousel(false)}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ProductCard;
