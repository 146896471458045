import React from "react";
import "../../assets/css/ProfileEdit.scss";

const ProfileEdit = ({ onCancel, onSave }) => {
  return (
    <>
      <div className="profile-edit-page-container">
        <div className="profile-edit-main-content">
          {/* Rest of the form inputs */}
          <div className="edit-two-input-box">
            <div className="label-and-input-box">
              <label> First Name </label>
              <input type="text" />
            </div>
            <div className="label-and-input-box">
              <label> Last Name </label>
              <input type="text" />
            </div>
          </div>
          <div className="edit-two-input-box">
            <div className="label-and-input-box">
              <label> Mobile Number </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> Email id </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> Gender </label>
              <input type="text" className="three-input" />
            </div>
          </div>
          <div className="edit-two-input-box">
            <div className="label-and-input-box">
              <label> Current Password </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> New Password </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> Confirm Password </label>
              <input type="text" className="three-input" />
            </div>
          </div>
          <div className="edit-two-input-box">
            <div className="label-and-input-box Address-input">
              <label> Address </label>
              <input type="text" />
            </div>
            <div className="label-and-input-box  city-input">
              <label> City </label>
              <input type="text" />
            </div>
          </div>
          <div className="edit-two-input-box">
            <div className="label-and-input-box">
              <label> State </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> Country </label>
              <input type="text" className="three-input" />
            </div>
            <div className="label-and-input-box">
              <label> Pincode </label>
              <input type="text" className="three-input" />
            </div>
          </div>
          {/* Other input fields */}
          <div className="profile-edit-buttons">
            <button className="cancel-button" onClick={onCancel}>
              CANCEL
            </button>
            <button className="save-button" onClick={onSave}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
