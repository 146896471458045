import React, { Fragment, useState, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const priceRangeDefaults = {
  lowest: 0,
  highest: 300000,
};
const priceRange = [
  {
    slug: "under-20000",
    name: "Under - ₹20,000",
  },
  { slug: "20000-50000", name: "₹20,000 - ₹50,000" },
  { slug: "50000-100000", name: "₹50,000 - ₹100,000" },
  { slug: "100000-200000", name: "₹100,000 - ₹200,000" },
  { slug: "200000-300000", name: "₹200,000 - ₹300,000" },
  { slug: "above-300000", name: "Above - ₹300,000" },
];
const FilterList = ({
  sizes,
  colors,
  rooms,
  patterns,
  shapes,
  collections,
  categories,
  filterParams,
  updateFilters,
  range = { lowest: 0, highest: 300000 },
}) => {
  const [isColorListOpen, setIsColorListOpen] = useState(false);
  const [isCategoryListOpen, setIsCategoryListOpen] = useState(false);
  const [isSizeListOpen, setIsSizeListOpen] = useState(false);
  const [isRoomListOpen, setIsRoomListOpen] = useState(false);
  const [isPatternListOpen, setIsPatternListOpen] = useState(false);
  const [isShapeListOpen, setIsShapeListOpen] = useState(false);
  const [isCollectionListOpen, setIsCollectionListOpen] = useState(false);
  // const [isPriceRangeListOpen, setIsPriceRangeListOpen] = useState(false);
  const [isLeftBoxVisible, setIsLeftBoxVisible] = useState(true);
  const [isPriceRangeListOpen, setIsPriceRangeListOpen] = useState(false);

  //   // Update price range based on the API-provided range
  //   if (range) {
  //     setPriceRange([range.lowest, range.highest]);
  //   }
  // }, [range]);

  useEffect(() => {
    if (filterParams.color) setIsColorListOpen(true);
    if (filterParams.size) setIsSizeListOpen(true);
    if (filterParams.room) setIsRoomListOpen(true);
    if (filterParams.pattern) setIsPatternListOpen(true);
    if (filterParams.shape) setIsShapeListOpen(true);
    if (filterParams.collection) setIsCollectionListOpen(true);
    if (filterParams.category) setIsCategoryListOpen(true);
    if (filterParams.priceRange) setIsPriceRangeListOpen(true);
  }, [filterParams]);

  const toggleColorList = () => setIsColorListOpen(!isColorListOpen);
  const toggleCategoryList = () => setIsCategoryListOpen(!isCategoryListOpen);
  const toggleSizeList = () => setIsSizeListOpen(!isSizeListOpen);
  const toggleRoomList = () => setIsRoomListOpen(!isRoomListOpen);
  const togglePatternList = () => setIsPatternListOpen(!isPatternListOpen);
  const toggleShapeList = () => setIsShapeListOpen(!isShapeListOpen);
  const toggleCollectionList = () =>
    setIsCollectionListOpen(!isCollectionListOpen);
  // const togglePriceRangeList = () =>
  //   setIsPriceRangeListOpen(!isPriceRangeListOpen);

  const handleFilterChange = (key, value) => {
    let updatedFilters = { ...filterParams };

    const currentValues = updatedFilters[key]
      ? updatedFilters[key].split("_")
      : [];

    if (currentValues.includes(value)) {
      const newValues = currentValues.filter((v) => v !== value);
      if (newValues.length > 0) {
        updatedFilters[key] = newValues.join("_");
      } else {
        delete updatedFilters[key];
      }
    } else {
      updatedFilters[key] = [...currentValues, value].join("_");
    }

    // Update the filters
    updateFilters(updatedFilters);
  };

  const isFilterSelected = (key, value) => {
    return filterParams[key] && filterParams[key].split("_").includes(value);
  };
  const togglePriceRangeList = () =>
    setIsPriceRangeListOpen(!isPriceRangeListOpen);
  // const handlePriceChange = (event) => {
  //   const newPrice = Number(event.target.value);
  //   setPriceRange([newPrice, priceRange[1]]);
  //   updateFilters({
  //     ...filterParams,
  //     priceRange: `${newPrice}-${priceRange[1]}`,
  //   });
  // };

  // const handlePriceChangeEnd = (event) => {
  //   const newPrice = Number(event.target.value);
  //   setPriceRange([priceRange[0], newPrice]);
  //   updateFilters({
  //     ...filterParams,
  //     priceRange: `${priceRange[0]}-${newPrice}`,
  //   });
  // };

  return (
    <Fragment>
      {isLeftBoxVisible && (
        <div className="abstract-left-box">
          <div className="shop-by-color-price-size">
            <h4>Shop by</h4>

            {/* Category */}
            {/* <div className="list">
              <div className="list-header" onClick={toggleCategoryList}>
                <p>Category</p>
                <span>
                  {isCategoryListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isCategoryListOpen && (
                <ul>
                  {categories.map((item) => (
                    <li key={item.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("category", item.slug)}
                        onChange={() =>
                          handleFilterChange("category", item.slug)
                        }
                      />
                      <label>{item.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div> */}

            {/* Size */}
            <div className="list">
              <div className="list-header" onClick={toggleSizeList}>
                <p>Size</p>
                <span>
                  {isSizeListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isSizeListOpen && (
                <ul>
                  {sizes.map((item) => (
                    <li key={item.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("size", item.slug)}
                        onChange={() => handleFilterChange("size", item.slug)}
                      />
                      <label>{item.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Color */}
            <div className="list">
              <div className="list-header" onClick={toggleColorList}>
                <p>Color</p>
                <span>
                  {isColorListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isColorListOpen && (
                <ul>
                  {colors.map((color) => (
                    <li key={color.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("color", color.slug)}
                        onChange={() => handleFilterChange("color", color.slug)}
                      />
                      <label>
                        <span
                          className="colorCircle"
                          style={{
                            backgroundColor: color.colorHexCode,
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        ></span>
                        {color.name}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Rooms */}
            <div className="list">
              <div className="list-header" onClick={toggleRoomList}>
                <p>Rooms</p>
                <span>
                  {isRoomListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isRoomListOpen && (
                <ul>
                  {rooms.map((room) => (
                    <li key={room.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("room", room.slug)}
                        onChange={() => handleFilterChange("room", room.slug)}
                      />
                      <label>{room.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Patterns */}
            <div className="list">
              <div className="list-header" onClick={togglePatternList}>
                <p>Patterns</p>
                <span>
                  {isPatternListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isPatternListOpen && (
                <ul>
                  {patterns.map((pat) => (
                    <li key={pat.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("pattern", pat.slug)}
                        onChange={() => handleFilterChange("pattern", pat.slug)}
                      />
                      <label>{pat.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Shapes */}
            <div className="list">
              <div className="list-header" onClick={toggleShapeList}>
                <p>Shapes</p>
                <span>
                  {isShapeListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isShapeListOpen && (
                <ul>
                  {shapes.map((shape) => (
                    <li key={shape.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("shape", shape.slug)}
                        onChange={() => handleFilterChange("shape", shape.slug)}
                      />
                      <label>{shape.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Collection */}
            <div className="list">
              <div className="list-header" onClick={toggleCollectionList}>
                <p>Collection</p>
                <span>
                  {isCollectionListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isCollectionListOpen && (
                <ul>
                  {collections.map((item) => (
                    <li key={item.slug}>
                      <input
                        type="checkbox"
                        checked={isFilterSelected("collection", item.slug)}
                        onChange={() =>
                          handleFilterChange("collection", item.slug)
                        }
                      />
                      <label>{item.name}</label>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Price Range */}
            <div className="list">
              <div className="list-header" onClick={togglePriceRangeList}>
                <p>Price Range</p>
                <span>
                  {isPriceRangeListOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>

              {isPriceRangeListOpen && (
                <div>
                  <ul>
                    {priceRange.map((item) => (
                      <li key={item.slug}>
                        <input
                          type="checkbox"
                          checked={isFilterSelected("priceRange", item.slug)}
                          onChange={() =>
                            handleFilterChange("priceRange", item.slug)
                          }
                        />
                        <label>{item.name}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FilterList;
