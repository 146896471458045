import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

const sizes = [
  { feet: "2x3", cm: "60x90", inches: "24x36", meters: "0.60x0.91" },
  { feet: "3x5", cm: "90x150", inches: "36x60", meters: "0.91x1.52" },
  { feet: "4x6", cm: "120x180", inches: "48x72", meters: "1.21x1.82" },
  { feet: "5x8", cm: "150x240", inches: "60x96", meters: "1.54x2.43" },
  { feet: "6x9", cm: "180x270", inches: "72x108", meters: "1.82x2.74" },
  { feet: "8x10", cm: "240x300", inches: "96x120", meters: "2.43x3.04" },
  { feet: "9x12", cm: "270x360", inches: "108x144", meters: "2.74x3.65" },
  { feet: "10x14", cm: "300x420", inches: "120x168", meters: "3.04x4.39" },
  { feet: "12x15", cm: "360x450", inches: "144x180", meters: "3.65x4.5" },
];

const SizeChart = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1, textAlign: "center", marginBottom: "16px" }}>
          Size Conversion Chart
        </div>
        <Button onClick={onClose} sx={{ fontSize: "24px", color: "#000" }}>
          <IoCloseOutline />
        </Button>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ border: "1px solid #ddd", color: "black" }}>
            <TableHead sx={{ background: "black", color: "#fff" }}>
              <TableRow>
                <TableCell
                  sx={{ borderRight: "1px solid #ddd", color: "#fff" }}
                >
                  Size in Feet
                </TableCell>
                <TableCell
                  sx={{ borderRight: "1px solid #ddd", color: "#fff" }}
                >
                  Size in CM
                </TableCell>
                <TableCell
                  sx={{ borderRight: "1px solid #ddd", color: "#fff" }}
                >
                  Size in Inches
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>Size in Meters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sizes.map((size, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {size.feet}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {size.cm}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {size.inches}
                  </TableCell>
                  <TableCell>{size.meters}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default SizeChart;
