import React, { Fragment, useEffect } from "react";
import Icon1 from "../../assets/images/icon-1.svg";
import Icon2 from "../../assets/images/icon-2.svg";
import Icon3 from "../../assets/images/icon-3.svg";
import Icon4 from "../../assets/images/icon-4.svg";
import Icon5 from "../../assets/images/icon-5.svg";
import image1 from "../../assets/images/image-part (1) copy.png";
import image2 from "../../assets/images/image2.png";
import card from "../../assets/images/card-img.png";
import water from "../../assets/images/water-resistant_1466483.svg";
import UV from "../../assets/images/protection_1466529.svg";
import stain from "../../assets/images/pet-care_9002461 3.svg";
import final from "../../assets/images/final.png";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/QualityStandards.scss";
import IconNew1 from "../../assets/images/Made To Last.png";
import IconNew2 from "../../assets/images/Soft Feel.png";
import IconNew3 from "../../assets/images/Premium Quality.png";
import IconNew4 from "../../assets/images/Diverse Selections.png";
import qualityimage from "../../assets/images/qualityimage.png";
import quality1 from "../../assets/images/quality1.jpg";
import quality2 from "../../assets/images/quality2.jpg";
import quality3 from "../../assets/images/quality3.jpg";
import quality4 from "../../assets/images/quality4.jpg";
import quality5 from "../../assets/images/quality5.jpg";
import qualitycardnew from "../../assets/images/qualitycardnew.png"
const QualityStandards = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleClick = () => {
    const data = {
      slug: "new-arrival",
      name: "New Arrivals",
      metaTitle: "New Arrivals",
      metaDescription: "New Arrival Rugs",
    };
    localStorage.setItem("selectedItem", JSON.stringify(data));
    window.location.href = `/${data.slug}`;
  };
  return (
    <Fragment>
      <Header />
      <div class="intro-QS-container">
        <div class="heading-QS">
          <h2>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>Quality Standards</span>
          </h2>
        </div>
      </div>
      <div class="new-qs-container">
        <div class="qs-intro">
          <h2>WHY D'DECOR ?</h2>
          <p>
            At D'Decor, we prioritize quality and craftsmanship in every rug we
            create. Our commitment to using the finest materials ensures
            durability and style, making our rugs a perfect investment for your
            home. Elevate your space with our unique designs that blend
            tradition and modernity.
          </p>
        </div>
        <div class="qs-icon-container">
          <div class="qs-icon-box">
            <div class="qs-icon">
              <img src={IconNew1} alt="" />
            </div>
            <div class="icon-content">
              <h5>Made to Last</h5>
              <p>
                Our rugs are designed for easy maintenance and are perfect for
                everyday use in your home.
              </p>
            </div>
          </div>
          <div class="qs-icon-box">
            <div class="qs-icon">
              <img src={IconNew2} alt="" />
            </div>
            <div class="icon-content">
              <h5>Soft Feel</h5>
              <p>
                Our rugs are a gentle touch and plush feel, providing ultimate
                comfort and softness underfoot.
              </p>
            </div>
          </div>
          <div class="qs-icon-box">
            <div class="qs-icon">
              <img src={IconNew3} alt="" />
            </div>
            <div class="icon-content">
              <h5>Premium Quality</h5>
              <p>
                Our rugs are expertly crafted using premium materials, offering
                timeless style and quality.
              </p>
            </div>
          </div>
          <div class="qs-icon-box">
            <div class="qs-icon">
              <img src={IconNew4} alt="" />
            </div>
            <div class="icon-content">
              <h5>Diverse Selections</h5>
              <p>
                Choose from a wide array of styles, designs, and colors from our
                rug collection, and discover the perfect fit for your space.
              </p>
            </div>
          </div>
          {/* <div class="qs-icon-box">
            <div class="qs-icon">
              <img src={Icon5} alt="" />
            </div>
            <div class="icon-content">
              <h5>Indoor & Outdoor</h5>
              <p>
                Versatile and durable, these rugs are designed to seamlessly
                enhance both indoor comfort and outdoor style.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div class="image-text-container">
        <div class="image">
          <img src={quality1} alt="" />
        </div>
        <div class="content-QS">
          <h4>Quality Materials</h4>
          <p>
            D’Decor has always been synonymous with a product made of the
            highest quality materials. The vast array of textures, techniques
            and materials are available in a variety of sizes to choose from.
          </p>
        </div>
        <div class="image">
          <img src={quality2} alt="" />
        </div>
      </div>
      <div class="qs-container-3">
        <div class="qs-card">
          <img src={qualitycardnew} alt="Trending" />
          <div class="card-content">
            <h2>Trending</h2>
            <p>Luxurious rugs for your home</p>
            <button onClick={handleClick}>View Products</button>
          </div>
        </div>

        {/* <div class="icon-box-container-2">
          <div class="icon-box-2">
            <img src={water} alt="" />
            <h6>Water Resistant</h6>
          </div>
          <div class="icon-box-2">
            <img src={UV} alt="" />
            <h6>UV Stabilized</h6>
          </div>
          <div class="icon-box-2">
            <img src={stain} alt="" />
            <h6>Stain Resistant</h6>
          </div>
        </div> */}

        <div class="craftmans-content">
          <h2>quality designs</h2>
          <p>
            For modern interiors, we offer a range of sleek and contemporary
            rugs featuring graphical geometric patterns or abstract designs that
            create a minimalist aesthetic.
          </p>
          <p>
            In contrast, for maximalists, our collections have a diverse range
            of decorative florals, bold stripes and checks as well as
            contemporary, on-trend designs.
          </p>
          <p>
            To adorn traditional rooms, we have a wide range of elegant classic
            patterns, such as Persian or Oriental designs, which bring a sense
            of timelessness and warmth to a space.
          </p>
          <p>
            If you prefer simplicity, our plain solid-colored rugs are an
            excellent choice. These rugs offer versatility and can act as a
            neutral foundation, allowing other elements in the room, such as
            furniture or artwork, to stand out without overwhelming the space.
          </p>
        </div>
      </div>
      <div class="final-container">
        <div class="final-content">
          <h2>quality construction</h2>
          <p>
            D’Decor Rugs are machine-made for the modern lifestyle yet present
            an outstanding handmade appeal. Due to the robust construction and
            the use of high-quality yarns, our rugs are exceptionally soft and
            remarkably easy to clean and maintain.
          </p>
        </div>
        <div class="final-image">
          <img src={quality4} alt="" />
        </div>
      </div>
      <div class="final-container">
        <div class="final-image final-image2">
          <img src={quality5} alt="" />
        </div>
        <div class="final-content">
          <h2>quality check</h2>
          <p>
            All yarns are meticulously selected based on strength, touch and
            thickness. During the weaving process of the rugs, visual inspection
            takes place. Upon completion of weaving and latex backing, a
            thorough inspection is conducted to identify and rectify any defects
            that may be present. Final inspection for quality check takes place
            prior to finishing and packaging of the rugs for shipment.
          </p>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default QualityStandards;
