import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons for the dropdown

const DropDownShopFooter = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown visibility

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleItemClick = (item) => {
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(item));
    }
    window.location.href = `/${item.slug}`;
  };
  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <h5>{title}</h5>
        {/* Conditionally show up or down arrow */}
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {/* Dropdown content, visible only when isOpen is true */}
      {isOpen && (
        <ul className="dropdown-content">
          {data.map((item, index) => (
            <li
              key={index}
              onClick={() => handleItemClick(item)}
              style={{
                cursor: "pointer",
              }}
            >
              <a>{item.name}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropDownShopFooter;
