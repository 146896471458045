import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCollections } from "../../store/collection-actions";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/CollectionMob.scss";
const Collection = () => {
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.collections.collections);

  const navigate = useNavigate();
  useEffect(() => {
    if (collections.length === 0) {
      dispatch(fetchCollections());
    }
  }, [dispatch, collections]);
  const handleItemClick = (item) => {
    // Save the clicked item data to localStorage as selectedItem
    localStorage.setItem("selectedItem", JSON.stringify(item));
    window.location.href = `/${item.slug}`;
    // The onMouseLeave function will be called to close the dropdown
    // onMouseLeave();
  };
  return (
    <Fragment>
      <Header />
      <div className="mobile-collection-cards-page-container">
        <div className="mobile-collection-cards-main-content">
          <div className="all-mobile-collection-cards">
            {collections
              .slice()
              .reverse()
              .map((item) => (
                <div
                  className="collection-mobile-card"
                  onClick={() => handleItemClick(item)}
                >
                  <div className="image-of-collection-mobile-card">
                    <img src={item.cover.url} alt="" />
                  </div>
                  <p>{item.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Collection;
