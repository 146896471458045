import React, { Fragment } from "react";
import "../../../assets/css/Collection.scss";

const Collection = ({ collection }) => {
  const handleItemClick = (e,item) => {
    
    // Save the clicked item data to localStorage as selectedItem
    localStorage.setItem("selectedItem", JSON.stringify(item));
    window.location.href = `/${item.slug}`;
    // The onMouseLeave function will be called to close the dropdown
    // onMouseLeave();
  };
  return (
    <Fragment>
      <div className="head-col-part">
        <h2>FEATURED COLLECTIONS</h2>
      </div>
      <div className="grid-container">
        {collection.map((item, index) => (
          <Fragment>
            <div
              key={item._id}
              className={`grid-item ${index === 0 ? "grid-item-large" : ""}`}
              onClick={(e) => handleItemClick(e, item)}
            >
              <img src={item.cover.url} alt={`Image ${index + 1}`} />
              <div className="overlay">{item.name}</div>
            </div>
            {/* <div class="grid-item">
              <img src={item.cover.url} alt="Image 2" />
              <div class="overlay">{item.name}</div>
            </div> */}
          </Fragment>
        ))}
      </div>
      <div className="collection-mob-slide">
        {collection.map((item, index) => (
          <Fragment>
            <div
              key={item._id}
              className={`grid-item-mob`}
              onClick={(e) => handleItemClick(e, item)}
            >
              <img src={item.cover.url} alt={`Image ${index + 1}`} />
              <div className="overlay-mob">{item.name}</div>
            </div>
            {/* <div class="grid-item">
              <img src={item.cover.url} alt="Image 2" />
              <div class="overlay">{item.name}</div>
            </div> */}
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default Collection;
