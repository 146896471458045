import React, { Fragment, useState } from "react";
import CustomArrowLeft from "../../Common/CustomLeftArrow";
import CustomArrowRight from "../../Common/CustomRightArrow";
import Best1 from "../../../assets/images/best1.png";
import Best2 from "../../../assets/images/best2.png";
import Best3 from "../../../assets/images/best3.png";
import Best4 from "../../../assets/images/best4.png";
import "../../../assets/css/BestSeller.scss";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils";
const BestSeller = ({ bestSellers }) => {
  const navigate = useNavigate();

  const [startIndex, setStartIndex] = useState(0); // Starting index to show the initial items

  const handleNext = () => {
    if (startIndex + 4 < bestSellers.length) {
      setStartIndex(startIndex + 1); // Slide one product out and move the next one in
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1); // Slide one product back
    }
  };
  const handleMobNext = () => {
    if (startIndex + 1 < bestSellers.length) {
      setStartIndex(startIndex + 1);
    }
  };
  const handleViewDetails = (e, slug, size) => {
    e.stopPropagation();
    navigate(`/product/${slug}?size=${size}`);
  };
  return (
    <Fragment>
      <div className="head-best-part">
        <h2>BestSellers</h2>
      </div>
      <div className="product-carousel-2">
        <div className="product-slider slider-2">
          <button
            className="prev-btn"
            onClick={handlePrev}
            disabled={startIndex === 0}
          >
            <CustomArrowLeft />
          </button>

          <div className="slider-wrapper">
            <div
              className="slider-content"
              style={{
                transform: `translateX(-${startIndex * 25}%)`, // Adjust sliding by 25% for each item
                transition: "transform 0.5s ease-in-out", // Smooth transition
              }}
            >
              {bestSellers.map((product) => (
                <div
                  key={product._id}
                  className="best-product-item"
                  onClick={(e) =>
                    handleViewDetails(e, product.slug, product.sizes[0].slug)
                  }
                >
                  <div className="product-image">
                    <img
                      src={product.images[0].url}
                      alt={`Product ${product._id}`}
                    />
                  </div>
                  <div className="information">
                    <div className="head-info">
                      {/* <h3>{product.name}</h3> */}
                      {/* <h3>
                        {product.name
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}{" "}
                      </h3> */}
                      <h3>
                        {product.name
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </h3>

                      {product.priceSale ? (
                        <p>
                          <span className="original-price">
                            {formatPrice(product.price)}
                          </span>{" "}
                          <span className="sale-price">
                            {formatPrice(product.priceSale)}
                          </span>
                        </p>
                      ) : (
                        <p>{formatPrice(product.price)}</p>
                      )}
                    </div>
                    {/* <div className="best-product-price">Rs.{product.price}</div> */}
                  </div>
                  <button
                    className="buy-now-btn-2"
                    onClick={(e) =>
                      handleViewDetails(e, product.slug, product.sizes[0].slug)
                    }
                  >
                    {/* View Details */}
                    VIEW DETAILS
                  </button>
                </div>
              ))}
            </div>
          </div>

          <button
            className="next-btn"
            onClick={handleNext}
            disabled={startIndex + 4 >= bestSellers.length}
          >
            <CustomArrowRight />
          </button>
        </div>
      </div>
      <div className="product-carousel-2-mob">
        <div className="product-slider-mob slider-2-mob">
          {/* <button
            className="prev-btn"
            onClick={handlePrev}
            disabled={startIndex === 0}
          >
            <CustomArrowLeft />
          </button> */}

          <div className="slider-wrapper-mob">
            <div
              className="slider-content-mob"
              // style={{
              //   transform: `translateX(-${startIndex * 100}%)`, // Adjust sliding by 25% for each item
              //   transition: "transform 0.5s ease-in-out", // Smooth transition
              // }}
            >
              {bestSellers.map((product) => (
                <div
                  key={product._id}
                  className="best-product-item-mob"
                  onClick={(e) =>
                    handleViewDetails(e, product.slug, product.sizes[0].slug)
                  }
                >
                  <div className="product-image-mob">
                    <img
                      src={product.images[0].url}
                      alt={`Product ${product._id}`}
                    />
                  </div>
                  <div className="information-mob">
                    <div className="head-info-mob">
                      <h3>
                        {product.name
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </h3>

                      {product.priceSale ? (
                        <p>
                          <span className="original-price">
                            {formatPrice(product.price)}
                          </span>{" "}
                          <span className="sale-price">
                            {formatPrice(product.priceSale)}
                          </span>
                        </p>
                      ) : (
                        <p>{formatPrice(product.price)}</p>
                      )}
                    </div>
                    {/* <div className="best-product-price-mob">
                      Rs.{product.price}
                    </div> */}
                  </div>
                  <button
                    className="buy-now-btn-2-mob"
                    onClick={(e) =>
                      handleViewDetails(e, product.slug, product.sizes[0].slug)
                    }
                  >
                    {/* View Details */}
                    VIEW DETAILS
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* <button
            className="next-btn"
            onClick={handleMobNext}
            disabled={startIndex === bestSellers.length}
          >
            <CustomArrowRight />
          </button> */}
        </div>
      </div>
    </Fragment>
  );
};

export default BestSeller;
