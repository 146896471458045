import React, { Fragment, useState } from "react";
import CustomLeftArrow from "../../Common/CustomLeftArrow";
import CustomRightArrow from "../../Common/CustomRightArrow";
import Vector from "../../../assets/images/Vector.svg";
import "../../../assets/css/CustomerReview.scss";

const testimonials = [
  {
    text: "Exceeded my expectations! The rug is plush, stylish, and adds a luxurious feel to my room",
    author: "Kavita Sharma",
    // role: "Artist",
  },
  {
    text: "Great quality and stunning design. The rug has really transformed my room. Fantastic purchase from D'decor!",
    author: "Anil Choudhary",
    // role: "Singer",
  },
  {
    text: "Beautiful rug that instantly elevated my living space. The colors are vivid, and quality is outstanding!",
    author: "Pooja Reddy",
    // role: "Singer-Songwriter",
  },
  {
    text: "Best decision! I was skeptical about ordering online, but it's even better than expected—soft and warm!",
    author: "Sana Shaikh",
    // role: "Musician",
  },
];

const CustomerReview = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex + 3 < testimonials.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const handleMobNext = () => {
    if (currentIndex + 1 < testimonials.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Only render the slider if there are testimonials
  if (testimonials.length === 0) {
    return null; // Or you could return a message if preferred
  }

  // Get the testimonials to display
  const displayedTestimonials = testimonials.slice(
    currentIndex,
    currentIndex + 3
  );
  const displayedTestimonialsMob = testimonials.slice(
    currentIndex,
    currentIndex + 1
  );
  return (
    <Fragment>
      <div className="head-cust-part">
        <h2>Customer Reviews</h2>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-slider">
          <button
            className="arrowCust prevCust"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <CustomLeftArrow />
          </button>
          <div className="slider-wrapper">
            <div className="slider">
              {displayedTestimonials.map((testimonial, index) => (
                <div
                  className={`testimonial ${index === 1 ? "center-slide" : ""}`}
                  key={index}
                >
                  <img src={Vector} alt="" />
                  <h4>{testimonial.text}</h4>
                  <p>
                    {testimonial.author}
                    <br />
                    <span>{testimonial.role}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <button
            className="arrowCust nextCust"
            onClick={handleNext}
            disabled={currentIndex + 3 >= testimonials.length}
          >
            <CustomRightArrow />
          </button>
        </div>
      </div>
      <div className="testimonial-container-mob">
        <div className="testimonial-slider-mob">
          {/* <button
            className="arrowCust prevCust"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <CustomLeftArrow />
          </button> */}
          <div className="slider-wrapper-review-mob">
            <div className="slider-mob">
              {displayedTestimonials.map((testimonial, index) => (
                <div className={`testimonial-mob`} key={index}>
                  <img src={Vector} alt="" />
                  <h4>{testimonial.text}</h4>
                  <p>
                    {testimonial.author}
                    <br />
                    <span>{testimonial.role}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* <button
            className="arrowCust nextCust"
            onClick={handleMobNext}
            disabled={currentIndex + 1 >= testimonials.length}
          >
            <CustomRightArrow />
          </button> */}
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerReview;
