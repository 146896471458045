import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Payment.scss";
import cartone from "../../assets/images/cart1.png";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../store/order";
import { cartActions } from "../../store/cart";
import CheckoutOrderSummary from "../Common/CheckoutOrderSummary";
import axios from "axios";
import { baseURL } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import Loader from "../Common/Loader";
const PaymentCart = () => {
  const [user, setUser] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.order);
  const cartItems = useSelector((state) => state.cart.cart);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
    if (savedOrder.length > 0) {
      dispatch(orderActions.setOrder(savedOrder));
    }
  }, [dispatch]);
  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(orderItems));
  }, [orderItems]);
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  const handlePaymentMethod = (e, slug) => {
    e.stopPropagation();
    setPaymentMethod(slug);
  };
  const bankData = [
    { id: 1, slug: "upi", name: "UPI" },
    { id: 2, slug: "bank-transfer", name: "Bank transfer" },
    { id: 3, slug: "pay-online", name: "Pay Online" },
    { id: 4, slug: "COD", name: "Cash On Delivery" },
  ];

  const handlePayment = async () => {
    if (paymentMethod === "") {
      setSnackbarMessage("Please select one Payment method");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    } else {
      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        address: user.address,
        city: user.city,
        state: user.state,
        country: user.country,
        zip: user.zip,
      };
      const appliedCoupons =
        JSON.parse(localStorage.getItem("appliedCoupons")) || {};

      // Find the first coupon code associated with any item in the order
      let overallCouponCode = "";
      for (const item of cartItems) {
        if (appliedCoupons[item._id]) {
          overallCouponCode = appliedCoupons[item._id].code;
          break;
        }
      }
      const order = cartItems.map((item) => ({
        _id: item._id,
        name: item.name,
        slug: item.slug,
        price: item.price,
        priceSale: item.priceSale,
        available: item.available,
        pid: item._id,
        quantity: item.quantity,
        size: item.sizes?.slug,
        image: item.images[0]?.url,
        colors: item.colors[0]?.name,
        shapes: item.shapes[0]?.name,
        patterns: item.patterns[0]?.name,
        rooms: item.rooms[0]?.name,
        composition: item.composition,
        technique: item.technique,
        subtotal: item.price,
        sku: item.sku,
      }));

      const values = {
        couponCode: overallCouponCode, // Apply overall coupon code here
        paymentMethod: paymentMethod,
        items: order,
        user: userData,
        totalItems: cartItems.length,
        shipping: 20,
        paymentId: "",
      };
      try {
        setLoading(true);
        const response = await axios.post(`${baseURL}/orders`, values);

        localStorage.setItem("orderId", response.data.orderId);
        localStorage.removeItem("cart");
        localStorage.removeItem("order");
        localStorage.removeItem("appliedCoupons");
        cartItems.forEach((item) => {
          dispatch(cartActions.removeFromCart(item));
        });

        navigate("/order-success");
      } catch (error) {
        console.error("Payment failed:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <>
      <Header />
      <div className="address-page-container">
        <div className="header-of-login">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Checkout
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Shop - Track my Order
          </p>
        </div>
        <div className="checkout-address-content">
          <h5>ADDRESS</h5>
          <div className="checkout-address-form-left-right">
            <div className="checkout-address-form-left">
              <div className="address-of-person">
                <h6>
                  {user.firstName} {user.lastName}
                </h6>
                <div className="person-address-details">
                  <p>
                    {user.address + " "} {user.city} {user.state} {user.country}{" "}
                    {user.pincode}
                  </p>

                  <div className="edit-number-box">
                    <p>{user.phone}</p>
                    <p>
                      <a href="/checkoutAddress">Edit</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="payment-option">
                <h5>PAYMENT OPTIONS</h5>
                {bankData.map((item) => (
                  <div className="all-options-of-payment" key={item.id}>
                    <div className="bank-details">
                      <div className="circle-name">
                        <div
                          className={`emty-circle ${
                            paymentMethod === item.slug ? "filled-circle" : ""
                          }`}
                          onClick={(e) => handlePaymentMethod(e, item.slug)}
                        ></div>

                        <h6>{item.name}</h6>
                      </div>
                      <p>
                        {" "}
                        ₹{" "}
                        {cartItems.reduce(
                          (total, item) => total + item.price * item.quantity,
                          0
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="order-button">
                <button onClick={handlePayment} disabled={loading === true}>
                  {loading ? <Loader /> : "PLACE ORDER"}{" "}
                  {/* Show loader or button text */}
                </button>
              </div>
            </div>
            <CheckoutOrderSummary order={cartItems} />
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PaymentCart;
