import { createSlice } from "@reduxjs/toolkit";

const initialShopState = { shopData: [] };

const shopSlice = createSlice({
  name: "shops",
  initialState: initialShopState,
  reducers: {
    getShopData(state, action) {
      state.shopData = action.payload.shops;
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
