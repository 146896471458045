import React, { Fragment } from "react";
import "../../assets/css/TrackOrder.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const TrackOrder = () => {
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <Fragment>
      <Header />
      <div className="intro-track-container">
        <div className="heading-track">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            -{" "}
            <span
              onClick={() => handleRedirect("/rugs")}
              style={{ cursor: "pointer" }}
            >
              Shop -{" "}
            </span>
            <span>Track my Order</span>
          </h2>
        </div>
      </div>
      <div className="track-order-container">
        <h1>Track Your Order</h1>
        <p>
          To track your order, please enter your Order ID in the box below and
          press the "Track" button. This was given to you on your receipt and in
          the confirmation email you should have received.
        </p>

        <form className="track-order-form">
          <div className="form-group">
            <label for="order-id">Order ID</label>
            <input
              type="text"
              id="order-id"
              placeholder="Found in your order confirmation email."
              required
            />
          </div>
          <div className="form-group">
            <label for="billing-email">Billing Email</label>
            <input
              type="email"
              id="billing-email"
              placeholder="Email you used during checkout."
              required
            />
          </div>
          <button type="submit">TRACK</button>
        </form>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TrackOrder;
