import { createSlice } from "@reduxjs/toolkit";

const initialCollectionState = { collections: [] };

const collectionSlice = createSlice({
  name: "collections",
  initialState: initialCollectionState,
  reducers: {
    getCollection(state, action) {
      state.collections = action.payload.collections;
    },
  },
});

export const collectionActions = collectionSlice.actions;

export default collectionSlice.reducer;
