import React, { Fragment } from "react";
import Blog1 from "../../assets/images/Rectangle 6741.png";
import blogimage1 from "../../assets/images/blogimage1.jpg";
import blogimage2 from "../../assets/images/blogimage2.jpg";
import newblogimage3 from "../../assets/images/newblogimage3.jpg";
import newblogimage1 from "../../assets/images/newblogimage1.jpg";
import "../../assets/css/Blog.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const blogs = [
  {
    imgSrc: blogimage1,
    date: "July, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "Unleash Your Style: Statement Living with Abstract Rugs",
    slug: "blog-inner",
  },
  {
    imgSrc: blogimage2,
    date: "Sept, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "How to Choose the Perfect Rug for Every Room",
    slug: "blog-inner-two",
  },
  {
    imgSrc: newblogimage1,
    date: "Nov, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor",
    slug: "blog-inner-three",
  },
];

function handleRedirect(slug) {
  window.location.href = `/${slug}`;
}

export const Blog = () => {
  return (
    <Fragment>
      <Header />
      <div className="intro-blog-container">
        <div className="heading-blog">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - <span>Blogs</span>
          </h2>
        </div>
      </div>

      <div className="blog-main-container">
        {blogs.map((blog, index) => (
          <div
            key={index}
            className="blog-box"
            onClick={() => handleRedirect(blog.slug)}
            style={{ cursor: "pointer" }}
          >
            <div className="blog-img">
              <div className="blog-image-main">
                <img src={blog.imgSrc} alt="" />
              </div>
              <p>{blog.date}</p>
            </div>
            <div className="blog-content">
              <h3>{blog.title}</h3>
              <p className="truncate">
                {index === 0
                  ? "Abstract rugs break free from traditional patterns and offer a bold, creative appeal. Think of them as art for your floors—where splashes of color, geometric shapes, and fluid designs combine to create pieces that don't just lie underfoot, but elevate the entire room. If you’ve ever felt like your space was missing that something, it could be that you're overlooking the power of an abstract rug to pull everything together."
                  : index === 1
                  ? "Rugs have a transformative power that can elevate the look and feel of a room. Whether you want to create a cozy corner, add a splash of color, or tie the design together, the right rug can make all the difference. However, choosing the perfect rug for each room requires more than just picking something visually appealing. Here’s a guide to help you select rugs based on room size, style, and functionality."
                  : "A well-chosen rug is more than just a floor covering. It’s an art form, a design anchor, a mood-setter. Finding a rug that complements your space or even transforms it, can be an exhilarating journey of color, texture, and style. But what happens when that perfect pattern doesn’t quite work with the sofa or clashes with the drapery? The secret lies in the art of color harmony—knowing  and undeniably chic."}
              </p>

              <a href={`/${blog.slug}`} className="read-more">
                READ MORE
              </a>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </Fragment>
  );
};
