import { createSlice } from "@reduxjs/toolkit";

const initialMenuState = { menuData: [] };

const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuState,
  reducers: {
    fetchMenuDara(state, action) {
      state.menuData = action.payload.menus;
    },
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice.reducer;
