import React, { Fragment, useState, useEffect } from "react";
import "../../assets/css/Media.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import icon1 from "../../assets/images/mdi-light_television.svg";
import icon2 from "../../assets/images/hugeicons_brochure.svg";
import icon3 from "../../assets/images/ph_newspaper-thin.svg";
import icon4 from "../../assets/images/material-symbols-light_event-outline.svg";
import news1 from "../../assets/images/news1.png";
import news2 from "../../assets/images/news2.png";
import news3 from "../../assets/images/news3.png";
import news4 from "../../assets/images/news4.png";
import news5 from "../../assets/images/news5.png";
import news6 from "../../assets/images/news6.png";
import { TfiPrinter } from "react-icons/tfi";
import Television from "../Sections/Media/Television";
import PrintAds from "../Sections/Media/PrintAds";
import News from "../Sections/Media/News";
import Events from "../Sections/Media/Events";
import { PiNewspaper } from "react-icons/pi";
import { MdOutlineTv } from "react-icons/md";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { RiRadio2Line } from "react-icons/ri";

const Media = () => {
  // State to track the selected tab
  // const [activeTab, setActiveTab] = useState("tab1");
  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top on page load
  // }, []);
  // // Function to handle tab clicks
  // const handleTabClick = (tabId) => {
  //   setActiveTab(tabId); // Update the active tab
  // };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  // const handleNewsArticle = (item) => {
  //   window.open(item.link, "_blank");
  // };
  // const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
  const [currentSection, setCurrentSection] = useState("in the news"); // State to track the active section
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  const mediaData = [
    {
      name: "In the News",
      icon: <PiNewspaper />,
      section: "in the news",
    },
    {
      name: "Television Ads",
      icon: <MdOutlineTv />,
      section: "television",
    },
    {
      name: "Print Ads",
      icon: <HiOutlineNewspaper />,
      section: "print ads",
    },
    {
      name: "Events",
      icon: <RiRadio2Line />,
      section: "events",
    },
  ];
  // Handler for switching to edit mode
  // const handleEditClick = () => {
  //   setIsEditing(true);
  // };

  // Handler to exit edit mode (on cancel or save)
  // const handleExitEdit = () => {
  //   setIsEditing(false);
  // };

  // Render the content of the selected section
  const renderContent = () => {
    // if (isEditing) {
    //   return <Television onCancel={handleExitEdit} onSave={handleExitEdit} />;
    // }
    switch (currentSection) {
      case "television":
        return <Television />;
      case "print ads":
        return <PrintAds />; // Render the ProfileOrder component
      case "in the news":
        return <News />; // Add relevant content for other sections
      case "events":
        return <Events />; // Add relevant content for signout or logout action
      default:
        return null;
    }
  };
  return (
    <Fragment>
      <Header />
      <div className="intro-media-container">
        <div className="heading-media">
          <h2>
            {" "}
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>Media</span>
          </h2>
        </div>
      </div>

      <div className="media-main-content">
        <div className="media-menu-icons">
          {mediaData.map((item, index) => (
            <div
              className={`media-icons-details ${
                currentSection === item.section ? "active" : ""
              }`}
              key={index}
              onClick={() => setCurrentSection(item.section)} // Set the active section
            >
              {item.icon}
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <div className="media-border-div"></div>
        <div className="conditional-render-div">{renderContent()} </div>
        {/* Conditionally render content based on the active section */}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Media;
