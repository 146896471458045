import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Thankyou.scss";
import { SlCheck } from "react-icons/sl";
import axios from "axios";
import { baseURL } from "../../utils";
import moment from "moment";

const Thankyou = () => {
  const [orderDetails, setOrderDetails] = useState({});

  const orderId = localStorage.getItem("orderId");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/orders/${orderId}`);
        const result = response.data;
        setOrderDetails(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrderDetails();
  }, [orderId]);

  const thankyouData = [
    {
      name: "Order Number",
      detail: "158",
    },
    {
      name: "Date",
      detail: "February 08,2022",
    },
    {
      name: "Email",
      detail: "sdoio@gmail.com",
    },
    {
      name: "Total",
      detail: "₹ 61.00",
    },
    {
      name: "Payment Method",
      detail: "Credit Card",
    },
  ];

  return (
    <>
      <Header />
      <div className="thankyou-page-container">
        <div className="thankyou-main-content">
          <div className="thankyou-text-icon">
            <h3>Thank You For Your Order !</h3>
            <SlCheck />
          </div>
          <div className="detail-of-payment-process">
            <div className="payment-table">
              <h5>Thank you. Your order has been received.</h5>
              <div className="all-div-of-payment-details">
                <div className="name-email-details">
                  <p>Order Number</p>
                  <h6>{orderDetails?.orderNo || "N/A"}</h6>
                </div>
                <div className="name-email-details">
                  <p>Date</p>
                  <h6>
                    {orderDetails?.createdAt
                      ? moment(orderDetails.createdAt).format("MMMM D, YYYY")
                      : "N/A"}
                  </h6>
                </div>
                <div className="name-email-details">
                  <p>Email</p>
                  <h6>{orderDetails?.user?.email || "N/A"}</h6>
                </div>
                <div className="name-email-details">
                  <p>Total</p>
                  <h6>₹ {orderDetails?.total || "N/A"}</h6>
                </div>
                <div className="name-email-details">
                  <p>Payment Method</p>
                  <h6>{orderDetails?.paymentMethod || "N/A"}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Thankyou;
