import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart"; // Adjust the import path based on your folder structure
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Cart.scss";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import OrderSummary from "../Common/OrderSummary";
import { formatPrice } from "../../utils";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const paymentData = [
    { name: "EMI from ₹6386/month", plan: "View plans" },
    { name: "Save up to ₹100", plan: "View offers" },
  ];

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  useEffect(() => {
    setLoading(true);
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
    setLoading(false);
  }, [dispatch]);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems)); // Save cart to localStorage on update
  }, [cartItems]);

  const toggleQuantityDropdown = (e, index) => {
    e.stopPropagation();
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const selectQuantity = (e, index, quantity) => {
    e.stopPropagation();
    const item = cartItems[index];
    dispatch(cartActions.addToCart({ ...item, quantity })); // Update quantity in Redux store
    setOpenDropdownIndex(null); // Close the dropdown after selecting the quantity
  };

  const handleRemoveFromCart = (e, item) => {
    e.stopPropagation();
    dispatch(cartActions.removeFromCart(item));
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleProductRedirect = (e, item) => {
    e.stopPropagation();
    navigate(`/product/${item.slug}?size=${item.sizes.slug}`);
  };
  const handleRedirectShopping = () => {
    localStorage.removeItem("selectedItem");
    navigate("/rugs");
  };
  return (
    <>
      <Header />
      <div className="cart-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - Cart
          </p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {" "}
            {cartItems.length === 0 ? (
              <div className="empty-cart-page">
                {" "}
                <div className="empty-cart-content">
                  <h4 className="empty-cart-title">Your Cart is Empty!!!</h4>
                  <div className="empty-cart-button">
                    <button onClick={handleRedirectShopping}>
                      Back To Shopping
                    </button>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div className="cart-main-content">
                <h4>YOUR CART</h4>
                <div className="cart-left-right-box-content">
                  <div className="cart-left-box-content">
                    {cartItems.map((item, index) => (
                      <div
                        className={`cart-details-box ${
                          index === 0 ? "cart-border-bottom" : ""
                        }`}
                        key={item._id}
                        onClick={(e) => handleProductRedirect(e, item)}
                      >
                        <div className="cart-image">
                          <img src={item.images[0].url} alt="cart-item" />
                        </div>
                        <div className="cart-text-content">
                          <div className="title-price-div">
                            <div className="title-text">
                              <h5>
                                {item.name
                                  .toLowerCase()
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </h5>
                            </div>
                            <div className="cartPrice">
                              <p>
                                {" "}
                                {formatPrice(item.priceSale ?? item.price)}
                              </p>
                            </div>
                          </div>
                          <p>{item.description}</p>
                          <div className="color-size-address">
                            <p>Color: {item.colors[0].name}</p>

                            <p>Size: {item.sizes.name}</p>
                            <p>{item.sku}</p>
                          </div>
                          <div className="quantity-selector">
                            <button
                              onClick={(e) => toggleQuantityDropdown(e, index)}
                            >
                              {item.quantity}{" "}
                              {openDropdownIndex === index ? (
                                <IoMdArrowDropup />
                              ) : (
                                <IoMdArrowDropdown />
                              )}
                            </button>
                            {openDropdownIndex === index && (
                              <ul className="quantity-dropdown">
                                {[1, 2, 3, 4].map((qty) => (
                                  <li
                                    key={qty}
                                    onClick={(e) =>
                                      selectQuantity(e, index, qty)
                                    }
                                  >
                                    {qty}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          <div className="detail-remove-add-button">
                            <p className="text-p">
                              Estimated delivery in 3 to 5 business days
                            </p>
                            <div className="remove-add-button">
                              <p
                                className="remove-button"
                                onClick={(e) => handleRemoveFromCart(e, item)}
                              >
                                REMOVE
                              </p>
                              <p>|</p>
                              <p>ADD TO WISHLIST</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="cart-left-box-content-mob">
                    {cartItems.map((item, index) => (
                      <Fragment>
                        <div
                          className={`cart-details-box-mob`}
                          key={item._id}
                          onClick={(e) => handleProductRedirect(e, item)}
                        >
                          <div
                            className="cart-image-mob"
                            onClick={(e) => handleProductRedirect(e, item)}
                          >
                            <img src={item.images[0].url} alt="cart-item-mob" />
                          </div>
                          <div className="cart-text-content-mob">
                            <div className="title-price-div-mob">
                              <div className="title-text-mob">
                                <h5>
                                  {item.name
                                    .toLowerCase()
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </h5>
                              </div>
                              <div className="cartPrice-mob">
                                {formatPrice(item.price)}
                              </div>
                            </div>
                            <p className="description-mob">
                              {item.description}
                            </p>
                            <div className="color-size-address-mob">
                              <p>Color: {item.colors[0].name}</p>

                              <p>Size: {item.sizes.name}</p>
                              <p>{item.sku}</p>
                            </div>
                            <div className="quantity-selector-mob">
                              <button
                                onClick={(e) =>
                                  toggleQuantityDropdown(e, index)
                                }
                              >
                                {item.quantity}{" "}
                                {openDropdownIndex === index ? (
                                  <IoMdArrowDropup />
                                ) : (
                                  <IoMdArrowDropdown />
                                )}
                              </button>
                              {openDropdownIndex === index && (
                                <ul className="quantity-dropdown">
                                  {[1, 2, 3, 4].map((qty) => (
                                    <li
                                      key={qty}
                                      onClick={(e) =>
                                        selectQuantity(e, index, qty)
                                      }
                                    >
                                      {qty}
                                    </li>
                                  ))}
                                </ul>
                              )}
                              <p className="text-p-mob">
                                Estimated delivery in 3 to 5 business days
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="detail-remove-add-button-mob">
                          <div className="remove-add-button-mob">
                            <p
                              className="remove-button-mob"
                              onClick={(e) => handleRemoveFromCart(e, item)}
                            >
                              REMOVE
                            </p>
                            {/* <div className="divider-mob"></div> */}
                            <p className="divider-mob"></p>
                            <p>ADD TO WISHLIST</p>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                  <OrderSummary order={cartItems} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
