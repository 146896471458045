import React, { Fragment, useState,useEffect} from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Blog1 from "../../../assets/images/blog (1).png";
import Blog2 from "../../../assets/images/blog (2).png";
import Blog3 from "../../../assets/images/blog (3).png";
import "../../../assets/css/HomeRugs.scss";
import image2 from "../../../assets/images/rug1.png";
import image1 from "../../../assets/images/rug2.png";
import blogimage1 from "../../../assets/images/blogimage1.jpg";
import blogimage2 from "../../../assets/images/blogimage2.jpg";
import newblogimage3 from "../../../assets/images/newblogimage3.jpg";
import homesliderimage1 from "../../../assets/images/homesliderimage1.jpg";
import homesliderimage2 from "../../../assets/images/homesliderimage2.jpg";
import newblogimage1 from "../../../assets/images/newblogimage1.jpg";
const blogs = [
  {
    imgSrc: blogimage1,
    date: "July, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "Unleash Your Style: Statement Living with Abstract Rugs",
    slug: "unleash-your-style-statement-living-with-abstract-rugs",
    link: "/blog-inner",
  },
  {
    imgSrc: blogimage2,
    date: "Sept, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "How to Choose the Perfect Rug for Every Room",
    slug: "how-to-choose-the-perfect-rug-for-every-room",
    link: "/blog-inner-two",
  },
  {
    imgSrc: newblogimage1,
    date: "Nov, 2024",
    category: "Lorem Ipsum - Lore - Lorem",
    title: "The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor",
    slug: "how-to-choose-the-perfect-rug-for-every-room",
    link: "/blog-inner-three",
  },
];
const HomeRugs = () => {
  const [sliderValue, setSliderValue] = useState(50);
  const navigate = useNavigate(); // Initialize useNavigate
  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };
  const handleBlogClick = (link) => {
    navigate(link); // Navigate to the link passed as a parameter
  };
  const images = [homesliderimage1, homesliderimage2]; // Array of images
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0); // Track touch start
  const [touchEndX, setTouchEndX] = useState(0);
  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX); // Record touch starting X position
  };
  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].clientX); // Record touch ending X position
    handleSwipe(); // Determine swipe direction
  };
  const handleSwipe = () => {
    const swipeDistance = touchStartX - touchEndX;
    const threshold = 50; // Minimum swipe distance to trigger change
    if (swipeDistance > threshold) {
      // Swipe left (Next)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    } else if (swipeDistance < -threshold) {
      // Swipe right (Previous)
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };
  return (
    <Fragment>
      <div className="head-rug-part">
        <h2>Rug Inspirations</h2>
      </div>
      <div className="image-compare">
        <div className="image-wrapper">
          <div className="image-1">
            <img src={homesliderimage2} alt="Image 1" />
          </div>
          <div
            className="image-2"
            style={{
              clipPath: `inset(0 ${100 - sliderValue}% 0 0)`,
            }}
          >
            <img src={homesliderimage1} alt="Image 2" />
          </div>
          <div className="image-labels">
            <div
              className="label-1"
              style={{
                left: `${sliderValue}%`,
              }}
            >
              Living Room
            </div>
            <div
              className="label-2"
              style={{
                right: `${100 - sliderValue}%`,
              }}
            >
              Bed Room
            </div>
          </div>
          <div className="image-slider">
            <div className="dragLine" style={{ left: `${sliderValue}%` }}>
              <span></span>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              onChange={handleSliderChange}
            />
          </div>
        </div>
      </div>
      {/* <div className="image-compare-mobile-desing">
      <div className="slider-container">
        <div className="slider-image">
          <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
        </div>
      </div>
      </div> */}
       {/* <div
        className="image-compare-mobile-desing"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="slider-container">
          <div
            className="slider-image"
            style={{
              transform: `translateX(-${currentIndex * 104}%)`,
              transition: "transform 0.20s ease-in-out",
              display: "flex", // Ensure images align in a row
              gap:"20px",
              // width:"300px",
              height: "300px"
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  flexShrink: 0, // Prevent shrinking
                }}
              />
            ))}
          </div>
        </div>
      </div> */}
      <div className="head-krug-part">
        <h2>Know Your Rugs</h2>
      </div>
      <div className="blog-home-container">
        <div className="blog-container">
          {blogs.map((blog, index) => (
            <div
              className="blog-item"
              key={index}
              onClick={() => handleBlogClick(blog.link)}
              style={{ cursor: "pointer" }}
            >
              <div className="blog-home-image">
                <img src={blog.imgSrc} alt="Rug Image" />
              </div>
              <div className="blog-date">{blog.date}</div>
              <div className="blog-content">
                {/* <p className="blog-category">{blog.category}</p> */}
                <h3>{blog.title}</h3>
                <a
                  href={blog.link}
                  className="read-more"
                  onClick={(e) => e.stopPropagation()}
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default HomeRugs;
