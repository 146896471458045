import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = { categories: [] };

const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    getCategory(state, action) {
      state.categories = action.payload.categories;
    },
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice.reducer;
