import { createSlice } from "@reduxjs/toolkit";

const initalAuthState = {
  isLoggedIn: localStorage.getItem("isLoggedIn") === "true" || false,
  token: localStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: initalAuthState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    logout(state, action) {
      state.user = [];
      state.token = "";
      state.isLoggedIn = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
