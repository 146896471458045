import React from "react";
import "../../../assets/css/News.scss";
import news1 from "../../../assets/images/news1.png";
import news2 from "../../../assets/images/news2.png";
import news3 from "../../../assets/images/news3.png";
import news4 from "../../../assets/images/news4.png";
import news5 from "../../../assets/images/news5.png";
import news6 from "../../../assets/images/news6.png";

const News = () => {
  const newsData = [
    {
      title: "D’Decor launches Rugnovate..",
      date: "February 19, 2020",
      image: news1,
      link: "https://www.architectandinteriorsindia.com/products/7567-ddecor-launches-rugnovate",
    },
    {
      title: "‘Rugnovate’ your home with ..",
      date: "December 6, 2019",
      image: news1,
      link: "https://www.architectandinteriorsindia.com/products/6157-rugnovate-your-home-with-ddecors-rug-collection",
    },
    {
      title: "D'Decor Rugs launches ... ",
      date: "October 24, 2019",
      image: news2,
      link: "https://brandequity.economictimes.indiatimes.com/news/marketing/ddecor-rugs-launches-rugnovate-campaign/71731864",
    },
    {
      title: "D'Decor Rugs launches ... ",
      date: "October 23, 2019",
      image: news3,
      link: "https://www.lokmattimes.com/national/ddecor-rugs-launches-rugnovate-campaign/",
    },
    {
      title: "D’Decor Rugs launches ..",
      date: "October 23, 2019",
      image: news4,
      link: "https://www.socialnews.xyz/2019/10/23/ddecor-rugs-launches-rugnovate-campaign/",
    },
    {
      title: "Watch: Shah Rukh Khan and..",
      date: "October 19, 2019",
      image: news5,
      link: "https://www.peepingmoon.com/entertainment-news/news/36866/Watch-Shah-Rukh-Khan-and-Gauri-Khan-tell-you-sometimes-it-is-better-to-rug-novate-than-renovate-in-the-new-advertisement-for-a-lifestyle-brand.html",
    },
    {
      title: "This festive season, furnish...",
      date: "August 22, 2017",
      image: news6,
      link: "https://www.architecturaldigest.in/content/festive-season-furnish-home-ddecors-plush-new-rug-collection/",
    },
  ];
  const handleNewsArticle = (item) => {
    window.open(item.link, "_blank");
  };
  return (
    <div className="in-the-news-container">
      <div className="in-the-news-articles">
        {newsData.map((news) => (
          <div
            className="in-the-news-item"
            onClick={() => handleNewsArticle(news)}
          >
            <div className="news-image">
              <img src={news.image} alt={news.title} />
            </div>
            <div className="in-the-news-divider"></div>
            <div className="news-content">
              <div className="news-content-title">
                <p>{news.title}</p>
              </div>
              <div className="news-content-date">
                <p>{news.date}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
