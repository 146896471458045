import React, { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/Category.scss";

const Category = ({ categories }) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  const autoScroll = () => {
    const carousel = carouselRef.current;

    if (carousel) {
      const scrollAmount = carousel.clientWidth;
      // Scroll the carousel by its width
      carousel.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });

      // If we are near the end, go back to the start
      if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
        carousel.scrollTo({ left: 0, behavior: "smooth" });
      }
    }
  };
  const handleFilterClick = (value) => {
    const urlParams = new URLSearchParams();
    urlParams.set("category", value);

    navigate(`/category?${urlParams.toString()}`);
  };
  useEffect(() => {
    const intervalId = setInterval(autoScroll, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const handleItemClick = (item) => {
    // Save the clicked item data to localStorage as selectedItem
    localStorage.setItem("selectedItem", JSON.stringify(item));
    window.location.href = `/${item.slug}`;
    // The onMouseLeave function will be called to close the dropdown
    // onMouseLeave();
  };
  return (
    <Fragment>
      <div className="head-category-part">
        <h2>Shop By Category</h2>
      </div>
      <div className="wrapper">
        <ul className="carousel" ref={carouselRef}>
          {categories.map((category) => (
            <li className="card" onClick={() => handleItemClick(category)}>
              <div className="img">
                <img src={category.cover.url} alt="img" draggable="false" />
              </div>
              <h2>{category.name}</h2>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default Category;
