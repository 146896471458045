import React, { Fragment, useEffect, useState } from "react";
import "../../assets/css/StoreLocator.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { FaDirections } from "react-icons/fa";
import axios from "axios";
import { baseURL } from "../../utils";
import { Snackbar, Alert } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
const markerIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png", // Example marker icon
  iconSize: [30, 30],
  iconAnchor: [15, 40],
});
const AdjustMapBounds = ({ stores }) => {
  const map = useMap();

  useEffect(() => {
    if (stores.length > 0) {
      const bounds = L.latLngBounds(
        stores.map((store) => [store.latitude, store.longitude])
      );
      map.fitBounds(bounds, { padding: [50, 50] }); // Add padding for better visibility
    }
  }, [stores, map]);
  return null;
};
const StoreLocator = () => {
  const [location, setLocation] = useState({
    latitude: 19.076,
    longitude: 72.8777,
  });
  const [error, setError] = useState("");
  const [stores, setStores] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [detectCurrentLocation, setDetectLocation] = useState({
  //   latitude:null,
  //   longitude:null,
  // });
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const fetchStores = async (lat = "", lng = "") => {
    try {
      const response = await axios.get(
        `${baseURL}/seach-store?slug=${searchParams}&latitude=${lat}&longitude=${lng}`
      );
      if (response.data.success === true) {
        const { data } = response.data;
        setStores(data);
        addMarkersToMap(data); // Pass data to mapping function
      } else {
        setStores([]);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const detectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setLocation({ latitude: lat, longitude: lng });
          fetchStores(lat, lng); // Fetch stores after detecting the location
          setError(""); // Clear any previous errors
        },
        (err) => {
          setError("Location access denied.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const addMarkersToMap = (stores) => {
    const map = window.google?.maps || null; // Ensure Google Maps is loaded
    if (map) {
      stores.forEach((store) => {
        if (store.latitude && store.longitude) {
          new map.Marker({
            position: { lat: store.latitude, lng: store.longitude },
            map,
            title: store.name || "Store",
          });
        }
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };

  const searchLocation = () => {
    fetchStores();
  };

  const handleInputChange = (e) => {
    setSearchParams(e.target.value);
  };

  useEffect(() => {
    // Fetch initial data without params
    fetchStores();
  }, []);

  const handleRedirect = (item) => {
    window.location.href = item;
  };

  const handleDirection = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Fragment>
      <Header />
      <div className="intro-store-container">
        <div className="heading-store">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - <span>Store Locator</span>
          </h2>
        </div>
      </div>

      <div className="map-content">
        <div className="search-map">
          <div className="location-search-container">
            <h2>Where to buy</h2>
            <div className="search-bar">
              <input
                type="text"
                id="locationInput"
                placeholder="Enter State/City/Pincode"
                value={searchParams}
                onChange={(e) => handleInputChange(e)}
              />
              <button onClick={searchLocation}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.46981 16.5177C13.3839 16.5177 16.5568 13.3448 16.5568 9.43075C16.5568 5.51671 13.3839 2.34375 9.46981 2.34375C5.55577 2.34375 2.38281 5.51671 2.38281 9.43075C2.38281 13.3448 5.55577 16.5177 9.46981 16.5177Z"
                    stroke="#0C0C0C"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M14.5938 14.3242L18.7267 18.1592"
                    stroke="#0C0C0C"
                    stroke-miterlimit="10"
                  />
                </svg>
              </button>
            </div>
            <p className="or-text">Or</p>
            <button className="detect-location-btn" onClick={detectLocation}>
              DETECT NEAREST LOCATION
            </button>
          </div>

          <div className="location-display-container">
            <div className="location-display-list">
              {stores.map((item) => (
                <div className="location-display-item" key={item.code}>
                  <div className="location-display-address">
                    <div className="store-locator-name">
                      <span>
                        {item.name
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                    </div>
                    <span>
                      {item.addressLine1
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>{" "}
                    <span>
                      {item.addressLine2
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>{" "}
                    <span>
                      {item.addressLine3
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>{" "}
                    <span>
                      {item.addressLine4
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>{" "}
                    <span>
                      {item.city
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </span>{" "}
                    <span>{item.state}</span>{" "}
                  </div>
                  <div
                    className="location-display-maplink"
                    onClick={() => handleDirection(item.googleMapLink)}
                  >
                    <div className="location-direction-title">Directions</div>
                    <div className="location-direction">
                      <FaDirections />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="map-container">
          <MapContainer
            center={[location.latitude, location.longitude]}
            zoom={10}
            style={{ height: "600px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <AdjustMapBounds stores={stores} />
            {stores.map((store, index) => (
              <Marker
                key={index}
                position={[store.latitude, store.longitude]}
                icon={markerIcon}
              >
                <Popup offset={[0, -30]}>
                  <div className="store-locator-pop">
                    <strong>{store.name}</strong>
                    <br />
                    <br />
                    {store.addressLine1}{" "}
                    {store.addressLine2 !== "" && store.addressLine2} <br />
                    {store.addressLine3 !== "" && store.addressLine3}
                    {store.addressLine4 !== "" && store.addressLine4}
                    {store.city} <br /> {store.state} <br /> {store.postalCode}
                    <div
                      className="store-locator-maplink"
                      onClick={() => handleDirection(store.googleMapLink)}
                    >
                      <div className="store-locator-title">Directions</div>
                      <div className="store-locator-direction">
                        <FaDirections />
                      </div>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default StoreLocator;
