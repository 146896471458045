import React, { Fragment, useState } from "react";
import "../../../assets/css/Header.scss";
import "../../../assets/css/Dialog.scss";
import { useNavigate } from "react-router-dom";
import DropDownShop from "./DropDownShop";
import DropDownColl from "./DropDownColl";

const MenuList = ({ menuList }) => {
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);
  const [isCollSelected, setIsCollSelected] = useState(false);
  const [isShopHovered, setIsShopHovered] = useState(false);
  const [isCollHovered, setIsCollHovered] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); // Store active menu item

  const handleMouseEnterShop = (menu) => {
    setIsSelected(true);
    setIsShopHovered(true);
    setIsCollHovered(false);
    setIsCollSelected(false);
    setActiveMenu(menu); // Set the active menu item
  };

  const handleMouseLeaveShop = () => {
    if (!isShopHovered) {
      setIsSelected(false);
    }
  };

  const handleMouseEnterColl = (menu) => {
    setIsCollSelected(true);
    setIsCollHovered(true);
    setIsSelected(false);
    setIsShopHovered(false);
    setActiveMenu(menu); // Set the active menu item
  };

  const handleMouseLeaveColl = () => {
    if (!isCollHovered) {
      setIsCollSelected(false);
    }
  };

  const handleShopDropdownMouseEnter = () => {
    setIsShopHovered(true);
  };

  const handleShopDropdownMouseLeave = () => {
    setIsShopHovered(false);
    setIsSelected(false);
  };

  const handleCollDropdownMouseEnter = () => {
    setIsCollHovered(true);
  };

  const handleCollDropdownMouseLeave = () => {
    setIsCollHovered(false);
    setIsCollSelected(false);
  };

  const handleMenuClick = (menu) => {
    // Navigate to the menu's URL if no submenus are present
    if (!menu.submenus || menu.submenus.length === 0) {
      navigate(menu.url); // Use the menu's URL for redirection
    }
  };

  return (
    <Fragment>
      <div className="menuList">
        <ul className="headerList">
          {menuList.map((menu) => (
            <li
              key={menu._id}
              onMouseEnter={() => {
                if (menu.submenus?.length > 1) {
                  handleMouseEnterShop(menu);
                } else if (menu.submenus?.length === 1) {
                  handleMouseEnterColl(menu);
                } else {
                  // Reset states if the menu item has no submenus
                  setIsSelected(false);
                  setIsCollSelected(false);
                  setActiveMenu(null);
                }
              }}
              onMouseLeave={() =>
                menu.submenus?.length > 1
                  ? handleMouseLeaveShop()
                  : menu.submenus?.length === 1
                  ? handleMouseLeaveColl()
                  : null
              }
              onClick={() => handleMenuClick(menu)} // Handle click when there are no submenus
              style={{
                // fontWeight:
                //   (isSelected || isCollSelected) &&
                //   activeMenu?.title === menu.title
                //     ? 800
                //     : "normal",
                cursor: "pointer",
              }}
            >
              {menu.title}
            </li>
          ))}
        </ul>
      </div>

      {/* Show DropDownShop if there are multiple submenus */}
      {isSelected && activeMenu?.submenus?.length > 1 && (
        <div
          className="customDropDown"
          onMouseEnter={handleShopDropdownMouseEnter}
          onMouseLeave={handleShopDropdownMouseLeave}
        >
          <DropDownShop
            data={activeMenu.submenus} // Pass submenu data to DropDownShop
            onMouseEnter={handleShopDropdownMouseEnter}
            onMouseLeave={handleShopDropdownMouseLeave}
          />
        </div>
      )}

      {/* Show DropDownColl if there is only one submenu */}
      {isCollSelected && activeMenu?.submenus?.length === 1 && (
        <div
          className="customDropDown"
          onMouseEnter={handleCollDropdownMouseEnter}
          onMouseLeave={handleCollDropdownMouseLeave}
        >
          <DropDownColl
            data={activeMenu.submenus[0]} // Pass the single submenu to DropDownColl
            onMouseEnter={handleCollDropdownMouseEnter}
            onMouseLeave={handleCollDropdownMouseLeave}
          />
        </div>
      )}
    </Fragment>
  );
};

export default MenuList;
