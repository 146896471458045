import React, { useState, Fragment, useEffect } from "react";
import ProfileInfo from "./ProfileInfo";
import ProfileEdit from "./ProfileEdit";
import { updateUser } from "../../../utils";
import { Snackbar, Alert } from "@mui/material";
const MainProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    setUserData(savedUser);
  }, []);

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => setIsEditing(false);
  const handleSave = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const body = {
        ...values,
        gender: values.gender.toLowerCase(),
      };
      const response = await updateUser(body, token);

      if (response.success === true) {
        const updatedUser = response.data;
        const address = {
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
        };
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("address", JSON.stringify(address));
        setUserData(updatedUser);
        setIsEditing(false);
      } else {
        // console.log("hi");
        setSnackbarMessage(response.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {}
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <Fragment>
      {isEditing ? (
        <ProfileEdit
          onCancel={handleCancel}
          onSave={handleSave}
          userData={userData}
        />
      ) : (
        <ProfileInfo onEdit={handleEdit} userData={userData} />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default MainProfile;
