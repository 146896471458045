import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const rugsList = [
  {
    slug: "new-arrival",
    name: "New Arrivals",
    metaTitle: "New Arrivals",
    metaDescription: "New Arrival Rugs",
  },
  {
    slug: "best-seller",
    name: "Best Sellers",
    metaDescription: "Best Seller Rugs",
  },
  {
    slug: "collection",
    name: "Rugs by Collection",
    metaDescription: "Rugs By Collection",
  },
  { slug: "colors", name: "Rugs by Colors", metaDescription: "Rugs By Colors" },
  { slug: "rugs", name: "All Rugs", metaDescription: "New Arrival Rugs" },
];

const ShopListMob = ({ subMenu }) => {
  const handleItemClick = (item, title) => {
    const selectedItem = { ...item, title: title };
    if (item.slug !== "rugs") {
      localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
    }
    if (item.slug === "rugs") {
      localStorage.removeItem("selectedItem");
    }
    window.location.href = `/${item.slug}`;
  };

  return (
    <div className="shopMenuListMob">
      <div className="rugsListMob">
        <List>
          <ListItem className="rugsHeaderMob">RUGS</ListItem>
          {rugsList.map((rug) => (
            <ListItem
              button
              key={rug.slug}
              onClick={() => handleItemClick(rug, "RUGS")}
              className="rugListMenuMob"
            >
              <ListItemText primary={rug.name} />
            </ListItem>
          ))}
        </List>
        <div className="mainSubMenuList">
          {subMenu.map((item) => (
            <List>
              <ListItem className="rugsHeaderMob">{item.title}</ListItem>
              {item.title === "Color"
                ? item.dataCollection.map((rug) => (
                    <ListItem
                      button
                      key={rug.slug}
                      onClick={() => handleItemClick(rug, "RUGS")}
                      className="rugListMenuMob"
                    >
                      <span
                        className="colorCircle"
                        style={{
                          backgroundColor: rug.name,
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      <ListItemText primary={rug.name} />
                    </ListItem>
                  ))
                : item.dataCollection.map((rug) => (
                    <ListItem
                      button
                      key={rug.slug}
                      onClick={() => handleItemClick(rug, "RUGS")}
                      className="rugListMenuMob"
                    >
                      <ListItemText primary={rug.name} />
                    </ListItem>
                  ))}
            </List>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShopListMob;
