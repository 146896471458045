import React, { Fragment, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/Banner.scss";
import CustomArrowLeft from "../../Common/CustomLeftArrow";
import CustomArrowRight from "../../Common/CustomRightArrow";

const Banner = ({ banners }) => {
  const swiperRef = useRef(null);
  console.log(banners);
  // useEffect(() => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //   }
  // }, [banners]);

  const handlePrevClick = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };
  const handleBannerRediect = (e, item) => {
    e.stopPropagation();
    if (item.buttonAction === "/new-arrival") {
      const dataToSave = {
        slug: "new-arrival",
        name: "New Arrivals",
        metaTitle: "New Arrivals",
        metaDescription: "New Arrival Rugs",
      };

      localStorage.setItem("selectedItem", JSON.stringify(dataToSave));
    } else if (item.buttonAction === "/best-seller") {
      const dataToSave = {
        slug: "best-seller",
        name: "Best Sellers",
        metaTitle: "Best Sellers",
        metaDescription: "Best Seller Rugs",
      };

      localStorage.setItem("selectedItem", JSON.stringify(dataToSave));
    } else {
      localStorage.removeItem("selectedItem");
    }
    window.location.href = item.buttonAction;
  };
  return (
    <Fragment>
      <div className="swiper-container">
        <Swiper
          ref={swiperRef}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: ".swiper-button-next-custom",
            prevEl: ".swiper-button-prev-custom",
          }}
          pagination={{ clickable: true }}
          onInit={(swiper) => {
            // Log the initial active index
          }}
        >
          {banners.length > 0 ? (
            banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <div
                  className="slideContent"
                  style={{ backgroundImage: `url(${banner.cover.url})` }}
                >
                  <div className="textContainer">
                    <h2>{banner.title}</h2>
                    <button
                      className="exploreBtn"
                      onClick={(e) => handleBannerRediect(e, banner)}
                    >
                      {banner.buttonText}
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <div>No banners available</div>
            </SwiperSlide>
          )}
        </Swiper>

        {/* Custom Navigation Arrows */}
        <div className="swiper-button-prev-custom" onClick={handlePrevClick}>
          <svg
            width="24"
            height="71"
            viewBox="0 0 24 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.339 1L0.978027 34.628L23.339 70.094"
              stroke="#F8F9FA"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
        <div className="swiper-button-next-custom" onClick={handleNextClick}>
          <svg
            width="35"
            height="78"
            viewBox="0 0 35 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66097 74L29.022 40.372L6.66097 4.906"
              stroke="#F8F9FA"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </div>
      <div className="swiper-container-mob">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]} // Include Pagination module
          pagination={{ clickable: true }} // Enable clickable dots
        >
          {banners.map((banner) => (
            <SwiperSlide>
              <div
                className="slideContent-mob"
                style={{ backgroundImage: `url(${banner.cover.url})` }}
              >
                <div className="textContainer-mob">
                  <h2>{banner.title}</h2>
                  <button
                    className="exploreBtn-mob"
                    onClick={(e) => handleBannerRediect(e, banner)}
                  >
                    {banner.buttonText}
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  );
};

export default Banner;
