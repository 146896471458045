import React, { useState, Fragment, useEffect } from "react";
import Vector from "../../../assets/images/Vector copy.svg";
import { useNavigate } from "react-router-dom";
import { TbStarFilled } from "react-icons/tb";
import { TbStarHalfFilled } from "react-icons/tb";
import { ImStarEmpty } from "react-icons/im";
import { addReview } from "../../../utils";
import { Snackbar, Alert } from "@mui/material";
const Tabs = ({ reviews, count, userReview, pid, product }) => {
  console.log("tabs", product);
  const [activeTab, setActiveTab] = useState("description");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  useEffect(() => {
    if (userReview?.rating) {
      setRating(userReview.rating);
    }
  }, [userReview]);
  useEffect(() => {
    if (userReview?.review) {
      setReviewText(userReview.review);
    }
  }, [userReview]);
  const isUserReviewEmpty =
    (typeof userReview === "object" &&
      !Array.isArray(userReview) &&
      Object.keys(userReview).length === 0) ||
    (Array.isArray(userReview) && userReview.length === 0);

  const userHasReview = !isUserReviewEmpty;
  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  // Function to render stars based on rating
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<TbStarFilled key={`full-${i}`} />);
    }

    // Add half star if applicable
    if (hasHalfStar) {
      stars.push(<TbStarHalfFilled key="half" />);
    }

    // Add empty stars to make total 5
    const emptyStarsCount = 5 - stars.length;
    for (let i = 0; i < emptyStarsCount; i++) {
      stars.push(<ImStarEmpty key={`empty-${i}`} />);
    }

    return stars;
  };

  const handleStarClick = (value) => {
    setRating(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoggedIn !== "true") {
      setSnackbarMessage("Please Sign In to review this product!!!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const review = {
        uid: user._id,
        pid: pid,
        rating: rating,
        review: reviewText,
      };
      const { ratingData, success } = await addReview(review, token);

      if (success === true) {
        setSnackbarMessage("Review Submitted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  return (
    <Fragment>
      <div className="tab-container">
        <div className="tab">
          <button
            className={`tablinks ${
              activeTab === "description" ? "active" : ""
            }`}
            onClick={() => openTab("description")}
          >
            DESCRIPTION
          </button>
          <button
            className={`tablinks ${activeTab === "rug-care" ? "active" : ""}`}
            onClick={() => openTab("rug-care")}
          >
            RUG CARE
          </button>
          <button
            className={`tablinks ${activeTab === "reviews" ? "active" : ""}`}
            onClick={() => openTab("reviews")}
          >
            REVIEWS {`(${count})`}
          </button>
          <button
            className={`tablinks ${activeTab === "why-choose" ? "active" : ""}`}
            onClick={() => openTab("why-choose")}
          >
            WHY CHOOSE D'DECOR
          </button>
          <button
            className={`tablinks ${activeTab === "shipping" ? "active" : ""}`}
            onClick={() => openTab("shipping")}
          >
            SHIPPING INFO
          </button>
        </div>

        {/* Tab content */}
        <div
          className="tabcontent"
          style={{ display: activeTab === "description" ? "block" : "none" }}
        >
          <div className="product-details">
            <table className="tableDetails">
              <tr>
                <th>Product No:</th>
                <td>{product.code}</td>
              </tr>
              <tr>
                <th>Collection:</th>
                <td>{product.collection}</td>
              </tr>
              <tr>
                <th>Technique:</th>
                <td>{product.technique}</td>
              </tr>
              <tr>
                <th>Composition:</th>
                <td>{product.composition}</td>
              </tr>
              <tr>
                <th>Design Type:</th>
                <td>{product.design}</td>
              </tr>
            </table>
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "rug-care" ? "block" : "none" }}
        >
          <div className="maintenance-tips">
            <div className="rug-care">
              <h5>Normal cleaning</h5>
              <ul>
                <li>Vacuum rug at least once a week</li>
                <li>
                  Rotate your carpet by 180 degrees every 6 months, as this will
                  decrease wear to heavily used areas
                </li>
              </ul>
            </div>
            <div className="rug-care">
              <h5>In the event of stains</h5>
              <ul>
                <li>Do not allow the stain to dry in</li>
                <li>
                  When treating stains always work from the outside to in, so
                  that you do not make the stain larger
                </li>
                <li>Soak up liquids with a kitchen roll or a kitchen towel</li>
                <li>Carefully pick up solids using knife or a spoon</li>
                <li>
                  Clean the area of the stain thoroughly by dabbing at it using
                  a sponge soaked in warm water mixed with a little detergent,
                  but remember not to scrub too hard
                </li>
                <li>
                  If desire, you can pat the cleaned area dry using a dry hand
                  towel or dry it with a hand dryer
                </li>
                <li>
                  If you cannot remove the stain yourself, it is advised to seek
                  help from a professional cleaning company
                </li>
              </ul>
            </div>
            {/* <div className="rug-care">
              <h5>Avoid exposure to sunlight</h5>
              <p>
                The carpet and mats must be protected from direct and continuous
                exposure to sunlight to retain color.
              </p>
            </div>
            <div className="rug-care">
              <h5>Use rug protectors</h5>
              <p>
                We recommend you use a rug protector or memory foam pad beneath
                the carpet to guard the rug when used under heavy furniture. 
              </p>
            </div>
            <div className="rug-care">
              <h5>Professional cleaning</h5>
              <p>
                We recommend periodic professional cleaning for higher
                durability of the carpet and rugs. Please note: In case the
                thread comes out, do not pull, instead trim with a pair of
                scissors.
              </p>
            </div> */}
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "reviews" ? "block" : "none" }}
        >
          <div className="testimonial-review-slider">
            {reviews.map((review, index) => (
              <div className="testimonial-slide active" key={index}>
                <div className="quote-mark">
                  <img src={Vector} alt="" />
                </div>
                <div className="testimonial-content">
                  <div className="author">
                    <p>
                      {review.firstName} {review.lastName}
                    </p>
                  </div>
                  <div className="rating-review">
                    {renderStars(review.rating)}{" "}
                    {/* Display stars based on rating */}
                  </div>
                  <p>{review.review}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="review-form-container">
            <h2>{userHasReview ? "Edit your review" : "Add a review"}</h2>
            <form onSubmit={handleSubmit}>
              <div className="rating-container">
                <label>Your rating*</label>
                <div className="rating-star">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      className="star"
                      data-value={value}
                      onClick={() => handleStarClick(value)}
                    >
                      {value <= rating ? (
                        <TbStarFilled />
                      ) : value === Math.ceil(rating) ? (
                        <TbStarHalfFilled />
                      ) : (
                        <ImStarEmpty />
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <textarea
                placeholder="Review"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              ></textarea>
              <button type="submit">
                {userHasReview ? "UPDATE REVIEW" : "SUBMIT"}
              </button>
            </form>
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "why-choose" ? "block" : "none" }}
        >
          <p className="why-choose">
            At D Decor, we prioritize quality and craftsmanship in every rug we
            create. Our commitment to using the finest materials ensures
            durability and style, making our rugs a perfect investment for your
            home. Elevate your space with our unique designs that blend
            tradition and modernity.
          </p>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "shipping" ? "block" : "none" }}
        >
          <div className="shippingInfo">
            <ul>
              <li> Free Shipping anywhere in India.</li>
              <li>Delivery Estimates: 3-5 business days for India.</li>
              <li>
                <b>“7 Days Hassle Free Return” </b>is valid for purchase within
                India.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="tab-container-list">
        <ul className="tab-mob">
          <li
            className={`tablinks-mob ${
              activeTab === "description" ? "active" : ""
            }`}
            onClick={() => openTab("description")}
          >
            DESCRIPTION
          </li>
          <li
            className={`tablinks-mob ${
              activeTab === "rug-care" ? "active" : ""
            }`}
            onClick={() => openTab("rug-care")}
          >
            RUG CARE
          </li>
          <li
            className={`tablinks-mob  ${
              activeTab === "reviews" ? "active" : ""
            }`}
            onClick={() => openTab("reviews")}
          >
            REVIEWS {`( ${count} ) `}
          </li>
          <li
            className={`tablinks-mob  ${
              activeTab === "why-choose" ? "active" : ""
            }`}
            onClick={() => openTab("why-choose")}
          >
            WHY CHOOSE D'DECOR
          </li>
          <li
            className={`tablinks-mob  ${
              activeTab === "shipping" ? "active" : ""
            }`}
            onClick={() => openTab("shipping")}
          >
            SHIPPING INFO
          </li>
        </ul>

        {/* Tab content */}
        <div
          className="tabcontent"
          style={{ display: activeTab === "description" ? "block" : "none" }}
        >
          <div className="product-details">
            {console.log(product.collection)}
            <table className="tableDetails">
              <tr>
                <th>Product No:</th>
                <td>{product.code}</td>
              </tr>
              <tr>
                <th>Collection:</th>
                <td>{product.collection}</td>
              </tr>
              <tr>
                <th>Technique:</th>
                <td>{product.technique}</td>
              </tr>
              <tr>
                <th>Composition:</th>
                <td>{product.composition}</td>
              </tr>
              <tr>
                <th>Design Type:</th>
                <td>{product.design}</td>
              </tr>
            </table>
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "rug-care" ? "block" : "none" }}
        >
          <div className="maintenance-tips">
            <div className="rug-care">
              <h5>Normal cleaning</h5>
              <ul>
                <li>Vacuum rug at least once a week</li>
                <li>
                  Rotate your carpet by 180 degrees every 6 months, as this will
                  decrease wear to heavily used areas
                </li>
              </ul>
            </div>
            <div className="rug-care">
              <h5>In the event of stains</h5>
              <ul>
                <li>Do not allow the stain to dry in</li>
                <li>
                  When treating stains always work from the outside to in, so
                  that you do not make the stain larger
                </li>
                <li>Soak up liquids with a kitchen roll or a kitchen towel</li>
                <li>Carefully pick up solids using knife or a spoon</li>
                <li>
                  Clean the area of the stain thoroughly by dabbing at it using
                  a sponge soaked in warm water mixed with a little detergent,
                  but remember not to scrub too hard
                </li>
                <li>
                  If desire, you can pat the cleaned area dry using a dry hand
                  towel or dry it with a hand dryer
                </li>
                <li>
                  If you cannot remove the stain yourself, it is advised to seek
                  help from a professional cleaning company
                </li>
              </ul>
            </div>
            {/* <div className="rug-care">
              <h5>Avoid exposure to sunlight</h5>
              <p>
                The carpet and mats must be protected from direct and continuous
                exposure to sunlight to retain color.
              </p>
            </div>
            <div className="rug-care">
              <h5>Use rug protectors</h5>
              <p>
                We recommend you use a rug protector or memory foam pad beneath
                the carpet to guard the rug when used under heavy furniture. 
              </p>
            </div>
            <div className="rug-care">
              <h5>Professional cleaning</h5>
              <p>
                We recommend periodic professional cleaning for higher
                durability of the carpet and rugs. Please note: In case the
                thread comes out, do not pull, instead trim with a pair of
                scissors.
              </p>
            </div> */}
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "reviews" ? "block" : "none" }}
        >
          <div className="testimonial-review-slider">
            {reviews.map((review, index) => (
              <div className="testimonial-slide active" key={index}>
                <div className="quote-mark">
                  <img src={Vector} alt="" />
                </div>
                <div className="testimonial-content">
                  <div className="author">
                    <p>
                      {review.firstName} {review.lastName}
                    </p>
                  </div>
                  <div className="rating-review">
                    {renderStars(review.rating)}{" "}
                    {/* Display stars based on rating */}
                  </div>
                  <p>{review.review}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="review-form-container">
            <h2>{userHasReview ? "Edit your review" : "Add a review"}</h2>
            <form onSubmit={handleSubmit}>
              <div className="rating-container">
                <label>Your rating*</label>
                <div className="rating-star">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      className="star"
                      data-value={value}
                      onClick={() => handleStarClick(value)}
                    >
                      {value <= rating ? (
                        <TbStarFilled />
                      ) : value === Math.ceil(rating) ? (
                        <TbStarHalfFilled />
                      ) : (
                        <ImStarEmpty />
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <textarea
                placeholder="Review"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              ></textarea>
              <button type="submit">
                {userHasReview ? "UPDATE REVIEW" : "SUBMIT"}
              </button>
            </form>
          </div>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "why-choose" ? "block" : "none" }}
        >
          <p className="why-choose">
            At D Decor, we prioritize quality and craftsmanship in every rug we
            create. Our commitment to using the finest materials ensures
            durability and style, making our rugs a perfect investment for your
            home. Elevate your space with our unique designs that blend
            tradition and modernity.
          </p>
        </div>

        <div
          className="tabcontent"
          style={{ display: activeTab === "shipping" ? "block" : "none" }}
        >
          <div className="shippingInfo">
            <ul>
              <li> Free Shipping anywhere in India.</li>
              <li>Delivery Estimates: 3-5 business days for India.</li>
              <li>
                <b>“7 Days Hassle Free Return” </b>is valid for purchase within
                India.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Tabs;
