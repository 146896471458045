import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import icons for the dropdown

const Dropdown = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown visibility

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <h5>{title}</h5>
        {/* Conditionally show up or down arrow */}
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {/* Dropdown content, visible only when isOpen is true */}
      {isOpen && (
        <ul className="dropdown-content">
          {data.map((item, index) => (
            <li key={index}>
              <a href={item.link}>{item.title}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
