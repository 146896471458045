import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import cardone from "../../../assets/images/cardone.png";
import { RxCross1 } from "react-icons/rx";
// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/css/HorizontelCarousel.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatPrice, getSimilarProducts } from "../../../utils";

const HorizontelCarousel = ({ onClose, pid }) => {
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSimilarProducts = async () => {
      if (pid) {
        const products = await getSimilarProducts(pid);
        setSimilarProducts(products);
      }
    };
    fetchSimilarProducts();
  }, [pid]);
  const carouselData = [
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    { image: cardone, name: "Omega", price: "₹45.00" },
    // Add more items as needed
  ];
  // Slick carousel settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust this number as needed for desktop
    slidesToScroll: 1,
    autoplay: true, // Enables automatic sliding
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleSimilarRedirect = (product) => {
    console.log(product);
    navigate(`/product/${product.slug}/${product.sizes[0].slug}`);
  };
  return (
    <div className="horizontal-carousel-container">
      <div className="horizontal-main-content">
        <div className="heading-cross-icon">
          <h5>Similar Products</h5>
          <RxCross1 onClick={onClose} />
        </div>
        <Slider {...settings} className="horizontal-carousel">
          {similarProducts.map((item, index) => (
            <div
              className="carousel-item"
              key={index}
              onClick={() => handleSimilarRedirect(item)}
            >
              <img src={item.images[0].url} alt={item.name} />
              <h6>{item.name 
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase())}</h6>
              <p>{formatPrice(item.price)}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HorizontelCarousel;
