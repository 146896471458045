import { createSlice } from "@reduxjs/toolkit";

const initialCartState = { cart: [] };

const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    addToCart(state, action) {
      const existingItem = state.cart.find(
        (item) => item._id === action.payload._id
      );
      if (!existingItem) {
        // If item is not in cart, add it with the selected quantity
        state.cart.push({
          ...action.payload,
          quantity: action.payload.quantity,
          sizes: action.payload.selectedSize,
        });
      } else {
        // If item exists, update its quantity

        existingItem.quantity = action.payload.quantity;
        existingItem.sizes = action.payload.selectedSize;
      }
    },
    removeFromCart(state, action) {
      state.cart = state.cart.filter((item) => item._id !== action.payload._id);
    },
    setCart(state) {
      const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
      state.cart = savedCart;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
