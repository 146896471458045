import { baseURL } from "../utils";
import axios from "axios";
import { shopActions } from "./shop";

export const fetchShopData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseURL}/masters`);
      const result = response.data;

      dispatch(
        shopActions.getShopData({
          shops: result.data || [],
        })
      );
    } catch (error) {}
  };
};
