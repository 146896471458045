import React, { useEffect, useState } from "react";
import { HiOutlineHome } from "react-icons/hi2";
import { LiaSearchSolid } from "react-icons/lia";
import { FiGrid } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa6";
import { BsCart3 } from "react-icons/bs";
import Badge from "@mui/material/Badge";
import SearchBarPopup from "../../Common/SearchBarPopup";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const HomeMobile = () => {
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cart);
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn"));
  }, []);
  const handleSearchPopupOpen = () => {
    setSearchPopupOpen(true);
  };

  const handleSearchPopupClose = () => {
    setSearchPopupOpen(false);
  };
  const handleCollection = () => {
    window.location.href = "/collection-list";
  };
  const handleCart = () => {
    window.location.href = "/cart";
  };
  const handleHome = () => {
    window.location.href = "/";
  };
  const handleAccountClick = () => {
    isLoggedIn === "true" ? navigate("/my-account") : navigate("/login");
  };
  const totalCartItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  return (
    <div className="home-page">
      <div className="home-content-icons">
        <div className="icon-title" onClick={handleHome}>
          <HiOutlineHome />
          <p>Home</p>
        </div>
        <div className="icon-title" onClick={handleSearchPopupOpen}>
          <LiaSearchSolid />
          <p>Search</p>
        </div>
        <div className="icon-title" onClick={handleCollection}>
          <FiGrid />
          <p>Collection</p>
        </div>
        <div className="icon-title" onClick={handleAccountClick}>
          <FaRegUser />
          <p>Account</p>
        </div>
        <div className="icon-title" onClick={handleCart}>
          <Badge
            badgeContent={totalCartItems} // Set the badge content to "0"
            showZero // Ensure the "0" is shown
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#000000",
                color: "#FFFFFF",
              },
            }}
          >
            <BsCart3 />
          </Badge>
          <p>Cart</p>
        </div>
      </div>
      <Dialog open={searchPopupOpen} onClose={handleSearchPopupClose}>
        <SearchBarPopup />
      </Dialog>
    </div>
  );
};

export default HomeMobile;
