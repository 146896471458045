import React, { useState } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";

const PaymentPage = () => {
  const [data, setData] = useState();
  const merchantCode = 386156; // Merchant Code
  const encryptionKey = "3805440761505014";

  // Function to generate a random reference number
  const generateReferenceNo = () => {
    return Math.random().toString(36).substring(2, 10); // Random alphanumeric string
  };

  // Function to encrypt fields
  const encryptField = (field) => {
    const parsedField = CryptoJS.enc.Utf8.parse(field);
    const parsedKey = CryptoJS.enc.Utf8.parse(encryptionKey); // Parse key as UTF-8
    return CryptoJS.AES.encrypt(parsedField, parsedKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  };

  const handlePayment = () => {
    const referenceNo = generateReferenceNo();
    const transactionAmount = "1"; // Fixed transaction amount

    const mandatoryFields = `${referenceNo}|45|${transactionAmount}`;
    const subMerchantId = "45";
    const payMode = 9;
    const returnURL = "https://ddecorrugs.beeonline.in/transaction";

    // Encrypt fields
    const encryptedMandatoryFields = encryptField(mandatoryFields);
    const encryptedReferenceNo = encryptField(referenceNo);
    const encryptedTransactionAmount = encryptField(transactionAmount);
    const encryptedSubMerchantId = encryptField(subMerchantId);
    const encryptedReturnURL = encryptField(returnURL);
    const encryptedPayMode = encryptField(payMode);

    const encryptedURL = `https://eazypay.icicibank.com/EazyPG?merchantid=${merchantCode}&mandatory fields=${encryptedMandatoryFields}&optional fields=&returnurl=${encryptedReturnURL}&Reference No=${encryptedReferenceNo}&submerchantid=${encryptedSubMerchantId}&transaction amount=${encryptedTransactionAmount}&paymode=${encryptedPayMode}`;

    // Open payment gateway in a new tab
    window.open(encryptedURL, "_blank");
  };
  const handlePost = async () => {
    try {
      const body = {
        name: "Apple MacBook Pro 16",
        data: {
          year: 2019,
          price: 1849.99,
          "CPU model": "Intel Core i9",
          "Hard disk size": "1 TB",
        },
      };
      const response = await axios.post(
        "https://api.restful-api.dev/objects",
        body
      );
      setData(response.data);
    } catch (error) {}
  };
  return (
    <div>
      <h2>Proceed to Payment</h2>
      <button onClick={handlePayment}>Pay Now</button>
      <button onClick={handlePost}>test</button>
      <div>{data?.name}</div>
    </div>
  );
};

export default PaymentPage;
