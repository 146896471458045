import React, { Fragment } from "react";
import image1 from "../../assets/images/Frame (1).svg";
import image2 from "../../assets/images/lucide_rocket.svg";
import image3 from "../../assets/images/icon-image.png";
import icon1 from "../../assets/images/image 37.svg";
import icon2 from "../../assets/images/image 38.svg";
import icon3 from "../../assets/images/image 39.svg";
import icon4 from "../../assets/images/image 40.svg";
import icon5 from "../../assets/images/image 41.svg";
import icon6 from "../../assets/images/image 42.svg";
import icon7 from "../../assets/images/image 43.svg";
import icon8 from "../../assets/images/image 44.svg";
import about from "../../assets/images/about-1.png";
import aboutus1 from "../../assets/images/aboutus1.jpg";
import aboutus2 from "../../assets/images/aboutus2.jpg";
import aboutnew1 from "../../assets/images/aboutnew1.jpeg";
import "../../assets/css/AboutUs.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const AboutUs = () => {
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  return (
    <Fragment>
      <Header />
      <div class="intro-about-container">
        <div class="heading-about">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - <span>About Us</span>
          </h2>
        </div>
      </div>
      <div class="about-us-1">
        <div class="about-content">
          <h2>who we are</h2>
          <p>
            D'Decor is the world’s largest producer of soft furnishing fabrics,
            creating over 120,000 sq. meters of high-quality fabrics daily.
            Founded in 1999, we are proud to be ‘globally local,’ understanding
            and adapting to the aesthetic sensibilities of every market we
            serve. Today, we partner with leading furniture and furnishing
            brands worldwide and have expanded beyond curtains and upholstery to
            offer bed & bath, blinds, wallpapers, and rugs. In India, D'Decor
            leads the category with a strong retail presence, including
            multi-brand outlets, exclusive stores, and an e-commerce platform.
          </p>
        </div>
        <div class="about-image">
          <img src={aboutnew1} alt="" />
        </div>
      </div>

      <div class="about-us-2">
        <div class="about-icon-container">
          <div class="icon-box">
            <div class="icon-1">
              <img src={image1} alt="" />
            </div>
            <div class="icon-content">
              <h4>vision</h4>
              <p>
                Becoming the ultimate choice for home furnishing solutions
                across the globe.
              </p>
            </div>
          </div>
          <div class="icon-box">
            <div class="icon-1">
              <img src={image2} alt="" />
            </div>
            <div class="icon-content">
              <h4>mission</h4>
              <p>
                Making homes beautiful in every street, in every city, of every
                country in the world.
              </p>
            </div>
          </div>
        </div>
        <div class="about-text">
          <h2>OUR INFRASTRUCTURE</h2>
          <p>
            5 state of the art plants in Tarapur, India is where all the magic
            is brought to life. Every year, 44 million meters of fabric are
            created here destined for homes around the world. Featuring cutting
            edge equipment with an average age of just 4 years, our Tarapur
            facilities are exemplary of how closely we have embraced technology
            and the spirit of re-invention. We are proud to have delivered
            several prominent firsts in Indian textile manufacturing such as
            creating water-repellent and flame-retardant fabrics as well as the
            country's first robotic warehouse.
          </p>
        </div>
      </div>

      <div class="icon-section">
        <div class="text-container">
          <img src={aboutus2} alt="" />
        </div>
        <div class="icon-box-container">
          <div class="icon-box">
            <img src={icon2} alt="" />
            <h4>Yarn Process</h4>
          </div>
          <div class="icon-box">
            <img src={icon3} alt="" />
            <h4>Fabric Weaving</h4>
          </div>
          <div class="icon-box">
            <img src={icon5} alt="" />
            <h4>Digital Printing</h4>
          </div>
          <div class="icon-box">
            <img src={icon8} alt="" />
            <h4>Robotized Warehousing</h4>
          </div>
          <div class="icon-box">
            <img src={icon1} alt="" />
            <h4>Embroidery</h4>
          </div>
          <div class="icon-box">
            <img src={icon4} alt="" />
            <h4>Fabric Processing</h4>
          </div>
          <div class="icon-box">
            <img src={icon6} alt="" />
            <h4>Readymade Stitching</h4>
          </div>
          <div class="icon-box">
            <img src={icon7} alt="" />
            <h4>Custom made Blinds</h4>
          </div>
        </div>
      </div>

      <div class="final-text">
        <h3>what we stand for</h3>
        <p>
          At D'Decor, we are driven by innovation, quality, and a passion for
          design. We create elegant, functional home décor that blends global
          trends with local tastes. Committed to craftsmanship and
          sustainability, we aim to enhance living spaces and inspire comfort in
          homes worldwide.
        </p>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AboutUs;
