import collectionReducer from "./collection";
import shopReducer from "./shop";
import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./category";
import cartReducer from "./cart";
import orderReducer from "./order";
import menuReducer from "./menu";
import authReducer from "./auth";
const store = configureStore({
  reducer: {
    auth: authReducer,
    collections: collectionReducer,
    shops: shopReducer,
    categories: categoryReducer,
    cart: cartReducer,
    order: orderReducer,
    menu: menuReducer,
  },
});

export default store;
