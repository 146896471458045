import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Image1 from "../../assets/images/Image [attachment-full].png";
// import Blog1 from "../../assets/images/Rectangle 6741.png";
// import img1 from "../../assets/images/img-6.jpg";
// import img2 from "../../assets/images/img-7.jpg";
// import img3 from "../../assets/images/img-8.png";
// import img4 from "../../assets/images/img-9.jpg";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import blogimage1 from "../../assets/images/blogimage1.jpg";
import blogimage2 from "../../assets/images/blogimage2.jpg";
import blogimage3 from "../../assets/images/blogimage3.jpg";
import blogimage4 from "../../assets/images/blogimage4.jpg";
import blogimage5 from "../../assets/images/blogimage5.jpg";
import blogimage6 from "../../assets/images/blogimage6.jpg";
import newblogimage3 from "../../assets/images/newblogimage3.jpg";
import newblogimage1 from "../../assets/images/newblogimage1.jpg";
import newblogimage2 from "../../assets/images/newblogimage2.jpg";
import "../../assets/css/BlogInner.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
const BlogInner = () => {
  const [clicked, setClicked] = useState(false); // Track whether the post box is clicked
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);
  const recentPosts = [
    {
      imgSrc: blogimage1,
      title: "Unleash Your Style: Statement Living with Abstract Rugs",
      date: "July, 2024",
      slug: "/blog-inner",
    },
    {
      imgSrc: blogimage2,
      title: "How to Choose the Perfect Rug for Every Room",
      date: "Sept, 2024",
      slug: "/blog-inner-two",
    },
  ];
  // Handle navigation when a post is clicked
  const handlePostClick = (slug) => {
    navigate(slug);
  };
  return (
    <Fragment>
      <Header />
      <div class="intro-bloginner-container">
        <div class="heading-bloginner">
          <h2>
            Home - <span>Blogs</span> -
            <span>
              The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor
            </span>
          </h2>
        </div>
      </div>

      <div class="blog-content-container">
        <div class="content-container-1">
          <div class="blog-image-box">
            <img src={newblogimage3} alt="" />
            <p>Nov, 2024</p>
          </div>
          <div class="blog-text">
            {/* <h6>Lorem Ipsum - Lorem</h6> */}
            <h3>
              The Art of Choosing Colors: Perfectly Pairing Your Rug with Décor
            </h3>
            <p>
              A well-chosen rug is more than just a floor covering. It’s an art
              form, a design anchor, a mood-setter. Finding a rug that
              complements your space or even transforms it, can be an
              exhilarating journey of color, texture, and style. But what
              happens when that perfect pattern doesn’t quite work with the sofa
              or clashes with the drapery? The secret lies in the art of color
              harmony—knowing how to match your rug with your décor in a way
              that feels effortless, natural, and undeniably chic. <br /> <br />
              Let’s delve into the essential elements that make this art so
              impactful, with a few expert tips to elevate your home’s
              aesthetic.
            </p>
            <h5>Start with a Color Palette</h5>
            <p>
              Decide on your room’s main color palette—primary, secondary, and
              accent hues. Primary hues are the dominant colors that set the
              tone for the room and occupy the most visual space. Secondary hues
              are complementary colors that support the primary colors and add
              depth to the palette. Accent hues are bold or contrasting colors
              used sparingly to add pops of interest, usually in accessories or
              smaller décor items.
            </p>
            <p>
              For instance, if your décor is neutral, a bold rug can become the
              centerpiece. For colorful spaces, consider a neutral or muted rug
              that won’t compete for attention.
            </p>
            <div class="blog-content-img">
              <img src={newblogimage1} alt="" />
            </div>
            <h5>Determine the Rug’s Role : Star or Supporting Cast?</h5>
            <p>
              Every room needs a focal point, and a rug can easily fill that
              role if chosen thoughtfully. A patterned rug in contrasting colors
              might be your ideal choice if you want the rug to be the star.
              Think abstract, geometric or vibrant floral patterns that
              immediately catch the eye. However, if your room already has
              strong visual elements—a statement sofa, eye- catching wallpaper,
              or a grand art piece—opt for a rug that supports rather than
              competes. A subtle tone-on-tone design or a monochrome style can
              add texture without detracting from other elements.
            </p>
            <h5>Use Contrast for Depth</h5>
            <p>
              Create depth by choosing a rug that contrasts with your furniture.
              A light sofa pairs well with a darker rug and vice versa, adding
              visual interest and avoiding a flat, overly matched look.
            </p>
            <div class="blog-content-img">
              <img src={newblogimage2} alt="" />
            </div>
            <h5>Synchronize with Existing Décor Materials</h5>
            <p>
              Take note of the materials and finishes already in your space, as
              this can influence your color choices. Natural woods, metals, and
              fabrics have inherent colors and undertones. A neutral-colored
              jute rug, for example, blends beautifully with rattan or bamboo
              furniture, creating an organic, nature-inspired look. Meanwhile,
              rugs with a slight sheen or muted metallic accents can work
              wonders in more modern, glam interiors, complementing reflective
              surfaces like mirrored furniture or polished metals.
            </p>
            <h5>Final Touches: Create Cohesion</h5>
            <p>
              Once your rug is in place, tie the room together by repeating
              subtle elements from the rug in other pieces throughout the room.
              Matching a single color or accent from the rug in your throw
              pillows, wall art, or even small decorative accents like vases or
              books can create a look that’s cohesive and well thought-out
              without looking overly matched. When matched thoughtfully, your
              rug won’t just blend in; it will enhance your entire room, adding
              both style and harmony.
            </p>
            <p>
              Discover the perfect rug to complement your décor by exploring our
              curated Rugs by Color at Ddecor Rugs—where color meets
              craftsmanship to elevate every space.
            </p>
          </div>
        </div>

        <div class="content-container-2">
          <h2>Recent Post</h2>
          {recentPosts.map((post, index) => (
            <div
              key={index}
              className="recent-post-box"
              onClick={() => handlePostClick(post.slug)} // Pass the slug dynamically
            >
              <div className="recent-img">
                <img src={post.imgSrc} alt="" />
              </div>
              <div className="recent-content">
                <h4>{post.title}</h4>
                <p>{post.date}</p>
              </div>
            </div>
          ))}

          {/* <div class="recent-post-box">
            <div class="recent-img">
              <img src={Blog1} alt="" />
            </div>
            <div class="recent-content">
              <h4>Unleash Your Style: Statement Living with Abstract Rugs</h4>
              <p>July 12, 2021</p>
            </div>
          </div>

          <div class="recent-post-box">
            <div class="recent-img">
              <img src={Blog1} alt="" />
            </div>
            <div class="recent-content">
              <h4>Unleash Your Style: Statement Living with Abstract Rugs</h4>
              <p>July 12, 2021</p>
            </div>
          </div>

          <div class="recent-post-box">
            <div class="recent-img">
              <img src={Blog1} alt="" />
            </div>
            <div class="recent-content">
              <h4>Unleash Your Style: Statement Living with Abstract Rugs</h4>
              <p>July 12, 2021</p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default BlogInner;
