import React from "react";
import "../../../assets/css/Television.scss";
import youtubeimage from "../../../assets/images/youtubeimage.png";

const Television = () => {
  return (
    <div className="">
      <h2 style={{ textAlign: "left" }}>Coming Soon...</h2>
      {/* <p>2024</p>
     <h2>RugNovate with D’Decor</h2>
     <div className='youtube-image-television'>
      <img  src={youtubeimage} alt='youtube-image'/>
     </div> */}
    </div>
  );
};

export default Television;
