import React, { useRef, useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import "../../../assets/css/VerticalCarousel.scss";
import cardone from "../../../assets/images/cardone.png";
import { formatPrice, getSimilarProducts } from "../../../utils";
import { useNavigate } from "react-router-dom";
const VerticalCarousel = ({ onClose, pid }) => {
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const scrollSpeed = 1;
  const scrollIntervalRef = useRef(null);

  const fetchSimilarProducts = async () => {
    if (pid) {
      try {
        const products = await getSimilarProducts(pid);
        if (Array.isArray(products.data)) {
          setSimilarProducts(products.data);
        } else {
          console.error("Expected an array, received:", products);
        }
      } catch (error) {
        console.error("Error fetching similar products:", error);
      }
    }
  };

  useEffect(() => {
    fetchSimilarProducts();
  }, [pid]);

  const startAutoScroll = () => {
    scrollIntervalRef.current = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollBy(0, scrollSpeed);
      }
    }, 50);
  };
  const handleSimilarRedirect = (product) => {
    console.log(product);
    navigate(`/product/${product.slug}?size=${product.sizes[0].size}`);
  };
  return (
    <div className="vertical-carousel-page-container">
      <div className="vertical-carousel-main-content">
        <div className="heading-cross-icon">
          <h5>Similar Products</h5>
          <RxCross1 onClick={onClose} />
        </div>

        <div className="carousel-cards">
          {Array.isArray(similarProducts) && similarProducts.length > 0 ? (
            similarProducts.map((item, index) => (
              <div
                className="carousel-card-content"
                key={index}
                onClick={() => handleSimilarRedirect(item)}
              >
                <div className="carousel-similar-img">
                  <img src={item.images[0]?.url || cardone} alt={item.name} />
                </div>
                <h6>{item.name
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase())}</h6>
                <p>{formatPrice(item.price)}</p>
              </div>
            ))
          ) : (
            <p>No similar products found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
