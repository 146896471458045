import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchCollections } from "../../../store/collection-actions";
import { useNavigate } from "react-router-dom";
const DropDownColl = ({ onMouseEnter, onMouseLeave, data }) => {
  // console.log(data);
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.collections.collections);
  const navigate = useNavigate();
  useEffect(() => {
    if (collections.length === 0) {
      dispatch(fetchCollections());
    }
  }, [dispatch, collections]);
  const handleFilterClick = (value, name) => {
    const urlParams = new URLSearchParams();
    urlParams.set("collection", value);
    localStorage.setItem("Filter", name);
    // Navigate to Shop page with updated query params
    navigate(`/collection?${urlParams.toString()}`);
    onMouseLeave();
  };
  const handleItemClick = (item) => {
    // Save the clicked item data to localStorage as selectedItem
    localStorage.setItem("selectedItem", JSON.stringify(item));
    window.location.href = `/${item.slug}`;
    // The onMouseLeave function will be called to close the dropdown
    onMouseLeave();
  };
  return (
    <div className="dropDownColContainer">
      <div className="collection">
        {data.dataCollection.map((item) => (
          <div className="collectionItem" onClick={() => handleItemClick(item)}>
            <div className="collectionItem-image">
              <img src={item.imageUrl} alt="" />
            </div>
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDownColl;
