import { baseURL } from "../utils";
import { collectionActions } from "./collection";
import axios from "axios";

export const fetchCollections = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${baseURL}/collections`);
      const result = response.data;

      dispatch(
        collectionActions.getCollection({
          collections: result.data || [],
        })
      );
    } catch (error) {}
  };
};
