import React, { useEffect, useState } from "react";
import "../../../assets/css/ProfileAddress.scss";

const ProfileAddress = () => {
  const [address, setAddress] = useState({});
  console.log(address);
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    setAddress(savedUser);
  }, [address]);
  const addressData = [
    {
      name: "Rahul Sharma",
      address:
        "Flat No. 12, ABC Apartments, 2nd Cross, MG Road, Koramangala, Bangalore - 560034 Karnataka, India",
      number: "9999999999",
    },
    {
      name: "Rahul Sharma",
      address:
        "Flat No. 12, ABC Apartments, 2nd Cross, MG Road, Koramangala, Bangalore - 560034 Karnataka, India",
      number: "9999999999",
    },
    {
      name: "Rahul Sharma",
      address:
        "Flat No. 12, ABC Apartments, 2nd Cross, MG Road, Koramangala, Bangalore - 560034 Karnataka, India",
      number: "9999999999",
    },
  ];
  return (
    <div className="profile-address-page-container">
      <div className="main-content-of-profile-address">
        <div className="profile-address-main-details-show">
          <h6>
            {address.firstName} {address.lastName}
          </h6>
          <p>
            {address.address} {address.city} {address.zip} {address.state}{" "}
            {address.country}
          </p>
          <div className="profile-address-number-button">
            <p>{address.phone}</p>
            {/* <button>Delete</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAddress;
