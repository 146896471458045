import { createSlice } from "@reduxjs/toolkit";

const initialOrderState = { order: [] };

const orderSlice = createSlice({
  name: "order",
  initialState: initialOrderState,
  reducers: {
    addOrder(state, action) {
      state.order = [{ ...action.payload }];
    },
    removeOrder(state, action) {
      state.order = state.order.filter(
        (item) => item._id !== action.payload._id
      );
    },
    setOrder(state) {
      const savedOrder = JSON.parse(localStorage.getItem("order")) || [];
      state.order = savedOrder;
    },
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice.reducer;
