import React, { Fragment, useEffect, useRef } from "react";
import "../../../assets/css/ProgramFeatured.scss";
import { useNavigate } from "react-router-dom";
const ProgramFeatured = ({ featuredIn }) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const autoScroll = () => {
    const carousel = carouselRef.current;

    if (carousel) {
      const scrollAmount = carousel.clientWidth;
      // Scroll the carousel by its width
      carousel.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });

      // If we are near the end, go back to the start
      if (carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth) {
        carousel.scrollTo({ left: 0, behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    const intervalId = setInterval(autoScroll, 2000);

    return () => clearInterval(intervalId);
  }, []);
  const handleRedirect = () => {
    navigate("/trade-program");
  };
  return (
    <Fragment>
      <div className="banner-part">
        <h1>JOIN THE TRADE PROGRAM</h1>
        <button onClick={handleRedirect}>EXPLORE</button>
      </div>
      <div className="featured-in">
        <div className="head-featured-part">
          <h2>FEATURED IN</h2>
        </div>
        <div className="logo-slider">
          <div className="slide-track" ref={carouselRef}>
            {featuredIn.map((item) => (
              <div className="slide" key={item._id}>
                <img src={item.cover.url} alt={item.title} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProgramFeatured;
